export const FieldData = {
  GeneralData: {
    // SerialNumber: {
    //   label: "Serial Number",
    //   // checkbox: true,
    //   // advanced: false,
    //   // role: "advanced",
    // },
    // StyleNumber: {
    //   label: "Style Number",
    //   // checkbox: true,
    //   // advanced: false,
    // },
    ItemType: {
      label: "Item Type",
      // checkbox: true,
      // advanced: false,
    },
    ItemSubtype: {
      label: "Item Subtype",
      // checkbox: false,
      // advanced: false,
    },
    Collection: {
      label: "Collection",
      // checkbox: true,
      // advanced: false,
    },
    SubCollection: {
      label: "Sub Collection",
      // checkbox: false,
      // advanced: false,
    },
    Description: {
      label: "Description",
      // checkbox: true,
      // advanced: false,
    },
    Maker: {
      label: "Maker",
      // checkbox: true,
      // advanced: false,
    },
    Brand: {
      label: "Brand",
      // checkbox: true,
      // advanced: false,
    },
    Provenance: {
      label: "Provenance",
      // checkbox: true,
      // advanced: false,
    },
    CircaDate: {
      label: "Circa Date",
      // checkbox: true,
      // advanced: false,
    },
  },
  Description: {
    Description: {
      label: "Description ",
      // checkbox: true,
      // advanced: false,
    },
    LongDescription: {
      label: "Long Description",
      // checkbox: true,
      // advanced: false,
    },
    Metal: {
      label: "Metal",
      // checkbox: true,
      // advanced: false,
    },
    MetalNote: {
      label: "Metal Note",
      // checkbox: true,
      // advanced: false,
    },
    ShortDescription: {
      label: "Short Description",
      // checkbox: true,
      // advanced: false,
    },
    StoneOrientation: {
      label: "Stone Orientation",
      // checkbox: true,
      // advanced: false,
    },
    WRShape: {
      label: "WRShape",
      // checkbox: true,
      // advanced: false,
    },
  },
  RingDetail: {
    PartwayEternity: {
      label: "Partway Eternity ",
      // checkbox: true,
      // advanced: false,
    },
    RingSize: {
      label: "Ring Size",
      // checkbox: true,
      // advanced: false,
    },
    SettingType: {
      label: "Setting Type",
      // checkbox: true,
      // advanced: false,
    },
    SideStoneCaratWeight: {
      label: "SideStoneCaratWeight",
      // checkbox: true,
      // advanced: false,
    },
  },
  DiamondDetail: {
    Clarity: {
      label: "Clarity",
      // checkbox: true,
      // advanced: false,
    },
    Color: {
      label: "Color ",
      // checkbox: true,
      // advanced: false,
    },
    DiamondCarats: {
      label: "Diamond Carats",
      // checkbox: true,
      // advanced: false,
    },
    DiamondDetails: {
      label: "Diamond Details",
      // checkbox: true,
      // advanced: false,
    },
  },
  ColorDetail: {
    ColorCarats: {
      label: "Color Carats",
      // checkbox: true,
      // advanced: false,
    },
    ColorComment: {
      label: "Color Comment",
      // checkbox: true,
      // advanced: false,
    },
  },

  CenterInfo: {
    CenterCaratWeight: {
      label: "Center Carat Weight ",
      // checkbox: true,
      // advanced: false,
    },
    CenterClarity: {
      label: "Center Clarity ",
      // checkbox: true,
      // advanced: false,
    },
    CenterColor: {
      label: "Center Color ",
      // checkbox: true,
      // advanced: false,
    },
    CenterCut: {
      label: "Center Cut ",
      // checkbox: true,
      // advanced: false,
    },
    CenterEnhancement: {
      label: "Center Enhancement ",
      // checkbox: true,
      // advanced: false,
    },
    CenterLabReportNbr: {
      label: "Center Lab Report Nbr ",
      // checkbox: true,
      // advanced: false,
    },
    CenterOrigin: {
      label: "Center Origin ",
      // checkbox: true,
      // advanced: false,
    },
    CenterShape: {
      label: "Center Shape ",
      // checkbox: true,
      // advanced: false,
    },
    CenterStoneNbr: {
      label: "Center Stone Nbr ",
      // checkbox: true,
      // advanced: false,
    },
    GemstoneType: {
      label: "Gemstone Type ",
      // checkbox: true,
      // advanced: false,
    },
  },

  Dimensions: {
    BangleSize: {
      label: "Bangle Size",
      // checkbox: false,
      // advanced: false,
    },
    CaseLength: {
      label: "Case Length",
      // checkbox: false,
      // advanced: false,
    },
    CaseWidth: {
      label: "Case Width",
      // checkbox: false,
      // advanced: true,
    },
    Diameter: {
      label: "Diameter",
      // checkbox: false,
      // advanced: false,
    },
    DropLength: {
      label: "DropLength",
      // checkbox: false,
      // advanced: false,
    },
    Height: {
      label: "Height",
      // checkbox: false,
      // advanced: false,
    },
    Length: {
      label: "Length",
      // checkbox: false,
      // advanced: false,
    },
    SizingNotes: {
      label: "SizingNotes",
      // checkbox: false,
      // advanced: false,
    },
    WidthOD: {
      label: "WidthOD",
      // checkbox: false,
      // advanced: false,
    },
    ClosureType: {
      label: "Closure Type",
      // checkbox: false,
      // advanced: false,
    },
    ElementWidth: {
      label: "Element Width",
      // checkbox: false,
      // advanced: false,
    },
    ElementLength: {
      label: "Element Length",
      // checkbox: false,
      // advanced: true,
    },
  },
  "Image/Videos": {
    EditorialVideo: {
      label: "EditorialVideo",
      // checkbox: true,
      // advanced: false,
    },
    LargeImageFileID: {
      label: "LargeImageFileID",
      // checkbox: true,
      // advanced: false,
    },
    LargeImageName: {
      label: "LargeImageName",
      // checkbox: true,
      // advanced: false,
    },
    SerialVideoLink: {
      label: "SerialVideoLink",
      // checkbox: true,
      // advanced: false,
    },
    StyleVideoLink: {
      label: "StyleVideoLink",
      // checkbox: true,
      // advanced: false,
    },
    WebImage1: {
      label: "Web Image1",
      // checkbox: true,
      // advanced: false,
    },
    WebImage2: {
      label: "Web Image2",
      // checkbox: true,
      // advanced: false,
    },
    WebImage3: {
      label: "Web Image3",
      // checkbox: true,
      // advanced: false,
    },
    WebImage4: {
      label: "Web Image4",
      // checkbox: true,
      // advanced: false,
    },
    WebImage5: {
      label: "Web Image5",
      // checkbox: true,
      // advanced: false,
    },
    hasWebImage: {
      label: "hasWebImage",
      // checkbox: true,
      // advanced: false,
    },
    IconImageFileID: {
      label: "IconImageFileID",
      // checkbox: true,
      // advanced: false,
    },
    IconImageName: {
      label: "IconImageName",
      // checkbox: true,
      // advanced: false,
    },
    webProductURL: {
      label: "webProductURL",
      // checkbox: true,
      // advanced: false,
    },
  },
  CertifiedDiamondReports: {
    ReportJpgUrls: {
      label: "ReportJpgUrls",
      // checkbox: true,
      // advanced: false,
    },
    ReportPdfUrls: {
      label: "ReportPdfUrls",
      // checkbox: true,
      // advanced: false,
    },
  },
};

export const DiamondFieldData = {
  DiamondDetail: {
    DiamondCaratWeight: {
      label: "Carat Weight",
      // checkbox: true,
      // advanced: false,
    },
    DiamondColorRange: {
      label: "Color",
      // checkbox: true,
      // advanced: false,
    },
    DiamondClarityRange: {
      label: "Clarity",
      // checkbox: true,
      // advanced: false,
    },
    Shape: {
      label: "Shape",
      // checkbox: true,
      // advanced: false,
    },
    Giashape: {
      label: "Gia Shape",
      // checkbox: true,
      // advanced: false,
    },
    StoneRatio: {
      label: "Stone Ratio",
      // checkbox: true,
      // advanced: false,
    },
    StoneFluoresence: {
      label: "Stone Fluoresence",
      // checkbox: true,
      // advanced: false,
    },
    Polish: {
      label: "Polish",
      // checkbox: true,
      // advanced: false,
    },
    Symmetry: {
      label: "Symmetry",
      // checkbox: true,
      // advanced: false,
    },
    TableP: {
      label: "TableP",
      // checkbox: true,
      // advanced: false,
    },
    DepthP: {
      label: "DepthP",
      // checkbox: true,
      // advanced: false,
    },
    StoneCulet: {
      label: "Stone Culet",
      // checkbox: true,
      // advanced: false,
    },

  //   StoneGirdle: {
  //     label: "Stone Girdle",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   GemstoneType: {
  //     label: "Gemstone Type",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   GIAReportDate: {
  //     label: "GIA Report Date",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   DiamondCut: {
  //     label: "Diamond Cut",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   MountedStockNumber: {
  //     label: "Mounted Stock Number",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  // },
  // MTSDiamondDetail: {
  //   DiamondCaratWeight: {
  //     label: "Carat Weight",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   MTSDiamondColorRange: {
  //     label: "Color",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   MTSDiamondClarityRange: {
  //     label: "Clarity",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   Shape: {
  //     label: "Shape",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   Giashape: {
  //     label: "Gia Shape",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   StoneRatio: {
  //     label: "Stone Ratio",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   StoneFluoresence: {
  //     label: "Stone Fluoresence",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   Polish: {
  //     label: "Polish",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   Symmetry: {
  //     label: "Symmetry",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   TableP: {
  //     label: "TableP",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   DepthP: {
  //     label: "DepthP",
  //     // checkbox: true,
  //     // advanced: false,
  //   },
  //   StoneCulet: {
  //     label: "Stone Culet",
  //     // checkbox: true,
  //     // advanced: false,
  //   },

    StoneGirdle: {
      label: "Stone Girdle",
      // checkbox: true,
      // advanced: false,
    },
    GemstoneType: {
      label: "Gemstone Type",
      // checkbox: true,
      // advanced: false,
    },
    GIAReportDate: {
      label: "GIA Report Date",
      // checkbox: true,
      // advanced: false,
    },
    DiamondCut: {
      label: "Diamond Cut",
      // checkbox: true,
      // advanced: false,
    },
    MountedStockNumber: {
      label: "Mounted Stock Number",
      // checkbox: true,
      // advanced: false,
    },
  },
  Measurements: {
    Length: {
      label: "Length",
      // checkbox: true,
      // advanced: false,
    },
    Depth: {
      label: "Depth",
      // checkbox: true,
      // advanced: false,
    },
    Width: {
      label: "Width",
      // checkbox: true,
      // advanced: false,
    },
  },
};

export const GemStoneFieldData = {
  DiamondDetail: {
    CaratWeight: {
      label: "Carat Weight",
      // checkbox: true,
      // advanced: false,
    },

    Shape: {
      label: "Shape",
      // checkbox: true,
      // advanced: false,
    },
    StoneRatio: {
      label: "Stone Ratio",
      // checkbox: true,
      // advanced: false,
    },
    GemEnhancement: {
      label: "Gem Enhancement",
      // checkbox: true,
      // advanced: false,
    },
    GemCuttingStyle: {
      label: "Gem Cutting Style",
      // checkbox: true,
      // advanced: false,
    },
    CountryofOrigin: {
      label: "Country of Origin",
      // checkbox: true,
      // advanced: false,
    },

    TableP: {
      label: "TableP",
      // checkbox: true,
      // advanced: false,
    },
    DepthP: {
      label: "DepthP",
      // checkbox: true,
      // advanced: false,
    },

    GemstoneType: {
      label: "Gemstone Type",
      // checkbox: true,
      // advanced: false,
    },
    GIAReportDate: {
      label: "Report Date",
      // checkbox: true,
      // advanced: false,
    },
    MountedStockNumber: {
      label: "Mounted Stock Number",
      // checkbox: true,
      // advanced: false,
    },
  },
  Measurements: {
    Length: {
      label: "Length",
      // checkbox: true,
      // advanced: false,
    },
    Depth: {
      label: "Depth",
      // checkbox: true,
      // advanced: false,
    },
    Width: {
      label: "Width",
      // checkbox: true,
      // advanced: false,
    },
  },
};
