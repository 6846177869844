export const data = [
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2019-04-11",
    UsrMountedStockNbr: null,
    RetailPrice: "14008000.000000",
    LargeImageName:
      "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-large.jpg",
    RetailPriceCode: "BEFXAFXX",
    ItemType: "Ring",
    ConsignmentCost: "8717000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D61902",
    InventoryID: 78900,
    ShortDescription:
      "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold, Cushion Center Diamond of 3.79 Carats, * Color, VVS2 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "4953.00",
    SerialStatus: "MADAVE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "1.61",
    WholesalePriceCode: "BFXXSGHS",
    AdjustmentNbr: "001976",
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: "3.79",
    IconImageName:
      "Inventory ID / SKU (F-17751C-0-DIA-18KP           )\\F-17751C-0-DIA-18KP-icon.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2021-05-19T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30438496,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "2.30",
    Description:
      "The Kwiat Setting Cushion Cut Diamond Engagement Ring with a Thin Pave Diamond Halo in 18K Rose Gold",
    CenterShape: "Cushion",
    MemoNbr: null,
    Maker: "Kwiat",
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 6",
    VendorID: "C000008346",
    CenterColor: "*",
    EngraveLimit: null,
    hasWebImage: "1",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-17751C-0-DIA-18KP",
    WebImage1: "F-17751C-0-DIA-18KP-1-1500.jpg",
    WebImage2: "F-17751C-0-DIA-18KP-2-1500.jpg",
    StatusDate: "2022-01-11T00:00:00",
    LongDescription: "",
    DiamondCarats: "4.30",
    WebImage5: null,
    Length: "",
    RFIDValue: "000074414825707733992036",
    WebImage3: "F-17751C-0-DIA-18KP-3-1500.jpg",
    WebImage4: "F-17751C-0-DIA-18KP-4-FS-1500.jpg",
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.15",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2022-01-11T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2195701309.jpg",
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "18 karat rose gold",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: "VVS2",
    FileIdNames: null,
    Invid: 78900,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "0",
    Color: "Blue Dia",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "6",
    MountingWholesale: "5375.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV78900&https://cdn4.kwiat.com/source-images/web/product/F-17751C-0-DIA-18KP-1-1500-product@2x.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: null,
    SerialNumber: "130695",
    StatusCustomerID: null,
    WholesalePrice: "10005375.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=5558A05F-6F64-439F-86DA-30EFA2D76879",
    Collection: "Bridal",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MADAVE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "AHIBLSG",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2195701309.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "1.70 MM",
    Height: null,
    InStockDate: "2019-04-11T00:00:00",
    MemoOutCustomer: null,
    SubCollection: "Bridal - The Kwiat Setting",
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "VVS2-SI2",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.40",
    IsRtv: "0",
    SerialSort: "130695",
    SideStoneCaratWeight: "0.51",
    DiamondDetails: "1CU54BR",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "8721953.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=D1522C19-CA03-4A96-AABE-7AE164667A3F",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2018-02-13",
    UsrMountedStockNbr: null,
    RetailPrice: "10085500.000000",
    LargeImageName:
      "Inventory ID / SKU (F-30043E-275-DIA-PT8W         )\\F-30043E-275-DIA-PT8W-large.jpg",
    RetailPriceCode: "BFXASXFX",
    ItemType: "Ring",
    ConsignmentCost: "0.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 82958,
    ShortDescription: "",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "11758.41",
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000007103",
    IsTiara: "0",
    MetalNote: "PLAT/18KW",
    ProfitMargin: null,
    TotalMarkup: "857.73",
    WholesalePriceCode: "HIFBLFX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2021-02-26T00:00:00",
    HoldNoteNbr: "HN-00008685",
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: "0.00",
    IconImageName:
      "Inventory ID / SKU (F-30043E-275-DIA-PT8W         )\\F-30043E-275-DIA-PT8W-icon.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2018-07-17T00:00:00",
    IsMounted: "0",
    HoldDate: "2021-02-16T00:00:00",
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30463049,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: null,
    Description: null,
    CenterShape: null,
    MemoNbr: "M0-307061",
    Maker: "Kwiat",
    StatusRefNbr: "M0-307061",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 6",
    VendorID: "C000008346",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-30043E-275-DIA-PT8W",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-03-15T00:00:00",
    LongDescription: "",
    DiamondCarats: "0.00",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.25",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: null,
    Provenance: null,
    SerialLastModifiedDate: "2021-02-11T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum and 18K white gold",
    StatusAmount: "14700.00",
    StatusCustomer: "William Goldberg Diamond Corp.",
    CenterClarity: null,
    FileIdNames: null,
    Invid: 82958,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: "DE",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "6",
    MountingWholesale: "14700.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=SER125673&acu=true&image=https://cdn4.kwiat.com/source-images/large/F-30043E-275-DIA-PT8W-large.jpg&desc=Emerald Cut, Platinum and 18K white gold Diamond Engagement Ring Style 125673&price=0&serial=125673&inv=82958&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: "Center dismounted- given to Russell",
    SerialNumber: "125673",
    StatusCustomerID: "C000007103",
    WholesalePrice: "7201900.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=00F2AACE-18B8-4DC7-BCF5-E6129B6C8338",
    Collection: null,
    HoldBy: "Tran, Nhan",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BXHSA",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2018-02-13T00:00:00",
    MemoOutCustomer: "William Goldberg Diamond Corp.",
    SubCollection: null,
    HoldNoteID: 8711,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "IF-VS2",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: null,
    IsRtv: "0",
    SerialSort: "125673",
    SideStoneCaratWeight: "4.26",
    DiamondDetails: "199BR 8EM",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "11758.41",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=7B74589F-9B74-487A-8ADD-46E047B628BD",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2022-02-03",
    UsrMountedStockNbr: null,
    RetailPrice: "6500000.000000",
    LargeImageName: null,
    RetailPriceCode: "TSFXXXX",
    ItemType: "Earrings",
    ConsignmentCost: "2500000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 97750,
    ShortDescription: "Oval Ruby and Diamond Drop Earrings",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: null,
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000033046",
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "2.60",
    WholesalePriceCode: "GISFXXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "0",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "049307",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName: null,
    IsSold: "0",
    VendorRefNbr: "E-2688",
    IsStuds: "0",
    PricingDate: "2022-02-03T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30471936,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: null,
    Description: "Oval Ruby and Diamond Drop Earrings",
    CenterShape: null,
    MemoNbr: "M0-310758",
    Maker: null,
    StatusRefNbr: "M0-310758",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: null,
    VendorID: "C000007326",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "E-E2688-0-DIARUB-18KW",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2022-02-04T00:00:00",
    LongDescription: null,
    DiamondCarats: "29.36",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.30",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: null,
    Provenance: null,
    SerialLastModifiedDate: "2022-02-05T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: null,
    SoldMarginCode: "",
    Metal: "18 karat white gold",
    StatusAmount: "6500000.00",
    StatusCustomer: "Suzy Edelman",
    CenterClarity: null,
    FileIdNames: null,
    Invid: 97750,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: null,
    Period: null,
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: null,
    webProductURL:
      "https://kwiat.com/?opt_product_id=SER133212&acu=true&image=&desc=Earrings, Style 133212&price=&serial=133212&inv=97750&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: null,
    SerialNumber: "133212",
    StatusCustomerID: "C000033046",
    WholesalePrice: "3250000.000000",
    IconImageFileID: null,
    Collection: null,
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "ISFXXXX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2022-02-03T00:00:00",
    MemoOutCustomer: "Suzy Edelman",
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: "2OV",
    CircaDate: null,
    Vendor: "Joseph Gad",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "2.00",
    IsRtv: "0",
    SerialSort: "133212",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: "11.61",
    InventoryCategory: "Kwiat Collections",
    InStockCost: "2500000.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID: null,
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2021-12-10",
    UsrMountedStockNbr: null,
    RetailPrice: "5800000.000000",
    LargeImageName:
      "Inventory ID / SKU (B-43074-FL-0-0                )\\B-43074-FL-0-0-large.jpg",
    RetailPriceCode: "SAFXXXX",
    ItemType: "Bracelet",
    ConsignmentCost: "2900000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 101286,
    ShortDescription: "Important Kashmir Sapphire and Diamond Bracelet",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: null,
    SerialStatus: "OFFICE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "2.00",
    WholesalePriceCode: "GTISFXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2024-12-26T00:00:00",
    HoldNoteNbr: "HN-00017832",
    PurchaseRefNumber: "046643",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (B-43074-FL-0-0                )\\B-43074-FL-0-0-icon.jpg",
    IsSold: "0",
    VendorRefNbr: "M2233",
    IsStuds: "0",
    PricingDate: "2021-12-20T00:00:00",
    IsMounted: "0",
    HoldDate: "2021-12-20T00:00:00",
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30318221,
    RetailSoldCustomer: null,
    FlushBand: "0",
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: null,
    Description: "Important Kashmir Sapphire and Diamond Bracelet",
    CenterShape: null,
    MemoNbr: null,
    Maker: null,
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: null,
    VendorID: "C000029600",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "B-43074-FL-0-0",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-12-10T00:00:00",
    LongDescription: null,
    DiamondCarats: null,
    WebImage5: null,
    Length: "",
    RFIDValue: "000074388587466552199002",
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "0",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.25",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: null,
    Provenance: null,
    SerialLastModifiedDate: "2022-01-05T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: null,
    SoldMarginCode: "",
    Metal: "Platinum",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: null,
    FileIdNames:
      " BRN30055C99463A_021358.pdf:01A841D2-29BE-4C5A-8607-0A9D227F8065",
    Invid: 101286,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "0",
    Color: null,
    Period: null,
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: null,
    webProductURL:
      "https://www.fredleighton.com/?opt_product_id=SERFL43074&acu=true&image=https://cdn4.kwiat.com/source-images/large/B-43074-FL-0-0-large.jpg&desc=Serial FL43074&price=&serial=FL43074&inv=101286&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Fred Leighton",
    HoldText: "not available- speak to Greg",
    SerialNumber: "FL43074",
    StatusCustomerID: null,
    WholesalePrice: "3625000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=D37F9919-F972-4040-86C0-E42F0FAFD4C8",
    Collection: "Vintage Jewelry",
    HoldBy: "Wright, Lara",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "OFFICE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "ILFXXXX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2021-12-10T00:00:00",
    MemoOutCustomer: null,
    SubCollection: null,
    HoldNoteID: 17887,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Trafalgar Jewellers",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.60",
    IsRtv: "0",
    SerialSort: "43074",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Vintage Jewelry",
    InStockCost: "2900000.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=B4B03017-B9C8-4018-97AD-4A904D22CB82",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2021-06-16",
    UsrMountedStockNbr: null,
    RetailPrice: "4721000.000000",
    LargeImageName: "139012-still.jpg",
    RetailPriceCode: "EHIBFXX",
    ItemType: "Ring",
    ConsignmentCost: "2996000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D67276",
    InventoryID: 100442,
    ShortDescription: "Cushion Center Diamond of 7.49 Carats, VS1 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "596.00",
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000028098",
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "1.58",
    WholesalePriceCode: "GXHBFXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2021-06-30T00:00:00",
    HoldNoteNbr: "HN-00011652",
    PurchaseRefNumber: null,
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: "7.49",
    IconImageName: "139012-still.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2021-06-16T00:00:00",
    IsMounted: "0",
    HoldDate: "2021-06-21T00:00:00",
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30317320,
    RetailSoldCustomer: null,
    FlushBand: "0",
    SerialVideoLink:
      "https://cdn.kwiat.com/kwiat/v360/imaged/139012/139012.html?sm=0&btn=0&z=0&sv=1&zoomslide=0&isFullLoad=1",
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: null,
    Description: null,
    CenterShape: "Cushion",
    MemoNbr: "M0-308119",
    Maker: "Kwiat",
    StatusRefNbr: "M0-308119",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: null,
    VendorID: "C000008346",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F30213C-0-DIA-PT8P",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-06-25T00:00:00",
    LongDescription: null,
    DiamondCarats: "7.49",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.12",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-06-25T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2191485757.jpg",
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum and 18K rose gold",
    StatusAmount: "3371000.00",
    StatusCustomer: "Artisan Global Alliance",
    CenterClarity: "VS1",
    FileIdNames: " 139012-still.jpg:8A3C2A92-83A0-4DEC-8285-CF8CABC1E84B",
    Invid: 100442,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "0",
    Color: "Fancy Pink",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: null,
    webProductURL:
      "https://kwiat.com/?opt_product_id=SER139012&acu=true&image=https://cdn4.kwiat.com/source-images/large/139012-still.jpg&desc=Serial 139012&price=&serial=139012&inv=100442&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: "Back safe",
    SerialNumber: "139012",
    StatusCustomerID: "C000028098",
    WholesalePrice: "3371000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=8A3C2A92-83A0-4DEC-8285-CF8CABC1E84B",
    Collection: "Bridal",
    HoldBy: "Tran, Nhan",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "ILXTSLT",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2191485757.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2021-06-16T00:00:00",
    MemoOutCustomer: "Artisan Global Alliance",
    SubCollection: null,
    HoldNoteID: 11707,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "VS1",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.40",
    IsRtv: "0",
    SerialSort: "139012",
    SideStoneCaratWeight: null,
    DiamondDetails: "1CU",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "2996596.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=8A3C2A92-83A0-4DEC-8285-CF8CABC1E84B",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2008-12-01",
    UsrMountedStockNbr: null,
    RetailPrice: "3700000.000000",
    LargeImageName: "80965-still.jpg",
    RetailPriceCode: "GHFXXXX",
    ItemType: "Ring",
    ConsignmentCost: "1557000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D63327",
    InventoryID: 78556,
    ShortDescription:
      "Princess Cut Diamond Engagement Ring with Two Trilliant Side Stones in Platinum, Asscher Center Diamond of 10.38 Carats, * Color, VS2 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "8622.96",
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: ".FX",
    MemoOutCustomerID: "V100617   ",
    IsTiara: "0",
    MetalNote: "PLAT/18KY",
    ProfitMargin: null,
    TotalMarkup: "2.36",
    WholesalePriceCode: "BASFXXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: "10.38",
    IconImageName: "80965-still.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2020-07-31T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30436396,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "5.00",
    Description:
      "Princess Cut Diamond Engagement Ring with Two Trilliant Side Stones in Platinum",
    CenterShape: "Asscher",
    MemoNbr: "M0-308289",
    Maker: "Kwiat",
    StatusRefNbr: "M0-308289",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: null,
    VendorID: "C000008346",
    CenterColor: "*",
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-17602P-0-DIA-PLAT",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-07-14T00:00:00",
    LongDescription: "",
    DiamondCarats: "1.40",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.18",
    EditorialVideo: null,
    SoldPrice: "0.00",
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-10-21T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2175205032.jpg",
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum",
    StatusAmount: "1850000.00",
    StatusCustomer: "Jonathan Doppelt, Inc.",
    CenterClarity: "VS2",
    FileIdNames: " 80965-still.jpg:C571347B-7159-40D5-AD5E-756C101650DC",
    Invid: 78556,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: null,
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: "10300.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV78556&https://cdn4.kwiat.com/source-images/large/80965-still.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: null,
    SerialNumber: "80965",
    StatusCustomerID: "V100617   ",
    WholesalePrice: "1850000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=C571347B-7159-40D5-AD5E-756C101650DC",
    Collection: "Bridal",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BSTSTIX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2175205032.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2008-12-01T00:00:00",
    MemoOutCustomer: "Jonathan Doppelt, Inc.",
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "2.00",
    IsRtv: "0",
    SerialSort: "80965",
    SideStoneCaratWeight: "1.40",
    DiamondDetails: " 2TR",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "1565622.96",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=C571347B-7159-40D5-AD5E-756C101650DC",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2021-09-08",
    UsrMountedStockNbr: null,
    RetailPrice: "3505000.000000",
    LargeImageName: "127408-still.jpg",
    RetailPriceCode: "GSFSFXX",
    ItemType: "Ring",
    ConsignmentCost: "2002000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D68903",
    InventoryID: 78705,
    ShortDescription:
      "Only You Oval Diamond Engagement Ring in Platinum, Oval Center Diamond of 25.03 Carats, D Color, VS2 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "575.00",
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000028075",
    IsTiara: "0",
    MetalNote: "PLAT",
    ProfitMargin: null,
    TotalMarkup: "1.75",
    WholesalePriceCode: "ISFGSFX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2022-02-25T00:00:00",
    HoldNoteNbr: "HN-00019363",
    PurchaseRefNumber: "040968",
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: null,
    CenterCaratWeight: "25.03",
    IconImageName: "127408-still.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2021-11-22T00:00:00",
    IsMounted: "0",
    HoldDate: "2022-02-04T00:00:00",
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30437196,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "7.40",
    Description: "Only You Oval Diamond Engagement Ring in Platinum",
    CenterShape: "Oval",
    MemoNbr: "M0-309856",
    Maker: "Kwiat",
    StatusRefNbr: "M0-309856",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 6",
    VendorID: "C000007103",
    CenterColor: "D",
    EngraveLimit: null,
    hasWebImage: "1",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-17685V-0-DIA-PLAT",
    WebImage1: "KW_ER_17685V_0_PLAT_1_1500.jpg",
    WebImage2: null,
    StatusDate: "2021-11-23T00:00:00",
    LongDescription: "",
    DiamondCarats: "25.56",
    WebImage5: "F-17685V-0-DIA-PLAT-5-FS-1500.jpg",
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: "KW_ER_17685V_0_PLAT-4-FS-1500.jpg",
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.25",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2022-01-09T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/5182619660.jpg",
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum",
    StatusAmount: "2503500.00",
    StatusCustomer: "Kwiat Wynn",
    CenterClarity: "VS2",
    FileIdNames: " 127408-still.jpg:7FB98C2D-D094-465D-AE4C-C3B878A46D3A",
    Invid: 78705,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: "D",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "6",
    MountingWholesale: "3500.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV78705&https://cdn4.kwiat.com/source-images/web/product/KW_ER_17685V_0_PLAT_1_1500-product@2x.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: "Memo to Goldberg",
    SerialNumber: "127408",
    StatusCustomerID: "C000028075",
    WholesalePrice: "2503500.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=7FB98C2D-D094-465D-AE4C-C3B878A46D3A",
    Collection: "Bridal",
    HoldBy: "Tran, Nhan",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "IFXISHS",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/5182619660.pdf",
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "2.20 MM",
    Height: null,
    InStockDate: "2021-09-08T00:00:00",
    MemoOutCustomer: "Kwiat Wynn",
    SubCollection: "Bridal - Only You",
    HoldNoteID: 19418,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "William Goldberg Diamond Corp.",
    Clarity: "VS2",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.40",
    IsRtv: "0",
    SerialSort: "127408",
    SideStoneCaratWeight: "0.53",
    DiamondDetails: "1OV24BR",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "2002575.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=7FB98C2D-D094-465D-AE4C-C3B878A46D3A",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2017-12-04",
    UsrMountedStockNbr: null,
    RetailPrice: "2805000.000000",
    LargeImageName:
      "Inventory ID / SKU (E-2478-0-DIA-PLAT             )\\E-2478-0-DIA-PLAT-large.jpg",
    RetailPriceCode: "IAFSFXX",
    ItemType: "Earrings",
    ConsignmentCost: "981767.28",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 70437,
    ShortDescription: "",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "18261.76",
    SerialStatus: "MEMO",
    DropLength: "1.50 inches",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000019841",
    IsTiara: "1",
    MetalNote: "PLAT",
    ProfitMargin: null,
    TotalMarkup: "2.80",
    WholesalePriceCode: "BHFXXXX",
    AdjustmentNbr: "001247",
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: "2021-10-08T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (E-2478-0-DIA-PLAT             )\\E-2478-0-DIA-PLAT-icon.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2022-01-27T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30345683,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Linked Jewelry",
    FinishedDWT: "9.00",
    Description: "Legacy Embrace Earrings with Diamonds in Platinum",
    CenterShape: null,
    MemoNbr: "M0-310666",
    Maker: "Kwiat",
    StatusRefNbr: "M0-310666",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "12.94 MM",
    RingSize: null,
    VendorID: "C000008346",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "1",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "E-2478-0-DIA-PLAT",
    WebImage1: "KW_EA_2478_0_PLAT_3_1500.jpg",
    WebImage2: null,
    StatusDate: "2022-01-26T00:00:00",
    LongDescription: "",
    DiamondCarats: "25.46",
    WebImage5: null,
    Length: "",
    RFIDValue: "000074478210282182034178",
    WebImage3: null,
    WebImage4: "KW_EA_2478_0_PLAT-4-FS-1500.jpg",
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.70",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Earrings - Pendant Earrings",
    Provenance: null,
    SerialLastModifiedDate: "2022-01-27T00:00:00",
    ReportJpgUrls:
      " https://cdn.kwiat.com/kwiat/certs-jpgs/1162342896.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/2185578775.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/5172655231.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/6255178938.jpg",
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum",
    StatusAmount: "1500000.00",
    StatusCustomer: "Kwiat, Inc. - Show",
    CenterClarity: null,
    FileIdNames: null,
    Invid: 70437,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: "FG",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "35.07 MM",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: "1497350.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV70437&https://cdn4.kwiat.com/source-images/web/product/KW_EA_2478_0_PLAT_3_1500-product@2x.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: null,
    SerialNumber: "124508",
    StatusCustomerID: "C000019841",
    WholesalePrice: "1700000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=316420E9-CCA2-4742-9787-F98BC8E7E626",
    Collection: "Legacy",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: "000074354557605749808020",
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BFXXXIL",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls:
      " https://cdn.kwiat.com/kwiat/certs-pdfs/1162342896.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/2185578775.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/5172655231.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/6255178938.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: "Earring - Post",
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2017-12-04T00:00:00",
    MemoOutCustomer: "Kwiat, Inc. - Show",
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: "Urena, Genesis",
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "VVS1-VS2",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.65",
    IsRtv: "0",
    SerialSort: "124508",
    SideStoneCaratWeight: null,
    DiamondDetails: "150BR 2PS",
    ColorCarats: null,
    InventoryCategory: "Kwiat Collections",
    InStockCost: "1000029.04",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=FB7D9FCE-18FC-45C6-85A0-7F3B1F0FD5DC",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2018-03-01",
    UsrMountedStockNbr: null,
    RetailPrice: "2500000.000000",
    LargeImageName:
      "Inventory ID / SKU (F-38984-FL-0-0                )\\F-38984-FL-0-0-FL38984-large.jpg",
    RetailPriceCode: "ISFXXXX",
    ItemType: "Ring",
    ConsignmentCost: "1511000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 90915,
    ShortDescription: "Pink and Blue Diamond Ring by Lauren Adriana",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "10.90",
    SerialStatus: "MADAVE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "1.65",
    WholesalePriceCode: "",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "000644",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (F-38984-FL-0-0                )\\F-38984-FL-0-0-FL38984-icon.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: null,
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30464929,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: null,
    Description: "Pink and Blue Diamond Ring by Lauren Adriana",
    CenterShape: null,
    MemoNbr: null,
    Maker: "Lauren Adriana",
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: null,
    VendorID: "C000005022",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-38984-FL-0-0",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-12-17T00:00:00",
    LongDescription:
      "1 Cushion Fancy Intense Purple-Pink Diamond 1.27cts, SI1.\r\nGIA Certificate 5151197416.\r\n1 Cushion Fancy Intense Blue Diamond 1.09cts, SI2.\r\nGIA Certificate 2165667103.\r\nSingle Cut Diamonds.\r\nSilver topped 18K Gold Mounting by Lauren Adriana.",
    DiamondCarats: null,
    WebImage5: null,
    Length: "",
    RFIDValue: "000074319915671513764340",
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "0",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: null,
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Gemstone Ring",
    Provenance: null,
    SerialLastModifiedDate: "2022-01-18T00:00:00",
    ReportJpgUrls: null,
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "SWISS",
    SoldMarginCode: "",
    Metal: null,
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: null,
    FileIdNames: " 38984.pdf:F253166B-1408-48F8-94E2-D833BEA30110",
    Invid: 90915,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: null,
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: null,
    webProductURL:
      "https://www.fredleighton.com/?opt_product_id=SERFL38984&acu=true&image=https://cdn4.kwiat.com/source-images/large/F-38984-FL-0-0-FL38984-large.jpg&desc= Engagement Ring Style FL38984&price=0&serial=FL38984&inv=90915&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Fred Leighton",
    HoldText: null,
    SerialNumber: "FL38984",
    StatusCustomerID: null,
    WholesalePrice: null,
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=EFE36E56-0678-48DC-997B-37B5F6E03C5A",
    Collection: "Lauren Adriana",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: "000074601423763517162597",
    Warehouse: "MADAVE",
    RFIDOldValue1: "000074418723963683472835",
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BSBXFBF",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2018-03-01T00:00:00",
    MemoOutCustomer: null,
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Lauren Adriana LTD",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: null,
    IsRtv: "0",
    SerialSort: "38984",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Modern Designers",
    InStockCost: "1511010.90",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=C284BCB4-2C9D-4BBE-9818-BC24EFD3E9FD",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2019-07-26",
    UsrMountedStockNbr: null,
    RetailPrice: "2465000.000000",
    LargeImageName:
      "Inventory ID / SKU (E-40385-FL-0-0                )\\E-40385-FL-0-0-FL40385-large.jpg",
    RetailPriceCode: "IETSFXX",
    ItemType: "Earrings",
    ConsignmentCost: "850000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 89527,
    ShortDescription:
      "40.11ct tw Cushion Cut Colombian Emerald and Diamond Pendant Earrings",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "0.00",
    SerialStatus: "MADAVE",
    DropLength: "2.67 inches",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: ".FX",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "2.90",
    WholesalePriceCode: "BIGISFX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "2",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "009226",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: "2021-12-03T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (E-40385-FL-0-0                )\\E-40385-FL-0-0-FL40385-icon.jpg",
    IsSold: "0",
    VendorRefNbr: "E3167",
    IsStuds: "0",
    PricingDate: "2019-03-27T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30463505,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: "14.84",
    Description:
      "40.11ct tw Cushion Cut Colombian Emerald and Diamond Pendant Earrings",
    CenterShape: null,
    MemoNbr: null,
    Maker: null,
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "22.65 MM",
    RingSize: null,
    VendorID: "C000026116",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "E-40385-FL-0-0",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-12-17T00:00:00",
    LongDescription:
      "1 Cushion Cut Colombian Emerald 23.09cts, Gubelin Report 15111143. Minor clarity enhancement.\n1 Cushion Cut Colombian Emerald 17.02cts, Gubelin Report 15116123. Minor clarity enhancement.\n50 Pear Shape Diamonds appx. 16.38cts, appx. G-I/VS.\n18K White Gold Mountings.",
    DiamondCarats: null,
    WebImage5: null,
    Length: "",
    RFIDValue: "000074037577526888882963",
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "0",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.45",
    EditorialVideo: null,
    SoldPrice: "0.00",
    ItemSubtype: "Earrings - Pendant Earrings",
    Provenance: null,
    SerialLastModifiedDate: "2022-01-09T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: null,
    SoldMarginCode: "",
    Metal: "18 karat white gold",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: null,
    FileIdNames:
      " 40385.pdf:BC9740BD-1EEE-451C-A912-7E09A2B37219|E-40385-FL-0-0-FL40385-icon.jpg:B353D632-8DB6-43E1-BC9B-E1216204C4C5|E-40385-FL-0-0-FL40385-large.jpg:FB12B6E5-3EA0-4AF1-94F9-D1A0DFECB246",
    Invid: 89527,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: null,
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "63.72 MM",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: "0.00",
    webProductURL:
      "https://www.fredleighton.com/?opt_product_id=SERFL40385&acu=true&image=https://cdn4.kwiat.com/source-images/large/E-40385-FL-0-0-FL40385-large.jpg&desc=18 karat white gold Earrings, Style FL40385&price=2465000&serial=FL40385&inv=89527&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Fred Leighton",
    HoldText: null,
    SerialNumber: "FL40385",
    StatusCustomerID: null,
    WholesalePrice: "1232500.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=B353D632-8DB6-43E1-BC9B-E1216204C4C5",
    Collection: null,
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MADAVE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "ASFXXX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: "Earring - Clip",
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2019-07-26T00:00:00",
    MemoOutCustomer: null,
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: "Urena, Genesis",
    ColorComment: null,
    CircaDate: null,
    Vendor: "KD Capital 2015",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "2.00",
    IsRtv: "0",
    SerialSort: "40385",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Vintage Jewelry",
    InStockCost: "850000.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=FB12B6E5-3EA0-4AF1-94F9-D1A0DFECB246",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2017-10-12",
    UsrMountedStockNbr: null,
    RetailPrice: "2463000.000000",
    LargeImageName: "124467-still.jpg",
    RetailPriceCode: "IETGFXX",
    ItemType: "Ring",
    ConsignmentCost: "1287499.91",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D54914",
    InventoryID: 78769,
    ShortDescription:
      "The Kwiat Setting Yellow Cushion Cut Diamond Engagement Ring in 18K Yellow Gold, Cushion Center Diamond of 20.65 Carats, VS1 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "75.00",
    SerialStatus: "MEMO",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000032149",
    IsTiara: "0",
    MetalNote: "18KY",
    ProfitMargin: null,
    TotalMarkup: "1.91",
    WholesalePriceCode: "BHSLFXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "0",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "002005",
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: "2021-09-08T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: "20.65",
    IconImageName: "124467-still.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2019-09-23T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30438042,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink:
      "https://cdn.kwiat.com/kwiat/v360/imaged/124467white/124467white.html?sm=0&btn=0&z=0&sv=1&zoomslide=0&isFullLoad=1",
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "6.37",
    Description:
      "The Kwiat Setting Yellow Cushion Cut Diamond Engagement Ring in 18K Yellow Gold",
    CenterShape: "Cushion",
    MemoNbr: "M0-308874",
    Maker: "Kwiat",
    StatusRefNbr: "M0-308874",
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 5",
    VendorID: "C000004181",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-17703CY-0-DIA-18KY",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-09-08T00:00:00",
    LongDescription: null,
    DiamondCarats: "20.65",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.35",
    EditorialVideo: "01bbb6d9901ece8d29a99e09e7fefc3a",
    SoldPrice: null,
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-09-08T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/2145657351.jpg",
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "18 karat yellow gold",
    StatusAmount: "1759000.00",
    StatusCustomer: "AKV Unique Inc.",
    CenterClarity: "VS1",
    FileIdNames: " 124467-still.jpg:3582EE46-5DF7-4044-994D-98A4D358F874",
    Invid: 78769,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: "Fancy Vivid Yellow",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "5",
    MountingWholesale: "3375.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV78769&https://cdn4.kwiat.com/source-images/large/124467-still.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: null,
    SerialNumber: "124467",
    StatusCustomerID: "C000032149",
    WholesalePrice: "1759000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=3582EE46-5DF7-4044-994D-98A4D358F874",
    Collection: "Bridal",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BIAHSHE",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/2145657351.pdf",
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "2.24 MM",
    Height: null,
    InStockDate: "2017-10-12T00:00:00",
    MemoOutCustomer: "AKV Unique Inc.",
    SubCollection: "Bridal - The Kwiat Setting",
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: "Urena, Genesis",
    ColorComment: null,
    CircaDate: null,
    Vendor: "Marco Racy Kheirallah",
    Clarity: "VS1",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: null,
    IsRtv: "0",
    SerialSort: "124467",
    SideStoneCaratWeight: null,
    DiamondDetails: "1FVY CU",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "1287574.91",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=3582EE46-5DF7-4044-994D-98A4D358F874",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2015-04-28",
    UsrMountedStockNbr: null,
    RetailPrice: "2227500.000000",
    LargeImageName:
      "Inventory ID / SKU (E-2358-0-DIA-PT8Y             )\\E-2358-0-DIA-PT8Y-large.jpg",
    RetailPriceCode: "IXXHSFX",
    ItemType: "Earrings",
    ConsignmentCost: "981393.61",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 74579,
    ShortDescription: "",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "7359.39",
    SerialStatus: "MEMO",
    DropLength: "1.75 inches",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: "C000028075",
    IsTiara: "0",
    MetalNote: "PLAT/18KY",
    ProfitMargin: null,
    TotalMarkup: "2.25",
    WholesalePriceCode: "BGSFXXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2021-04-30T00:00:00",
    HoldNoteNbr: "HN-00009606",
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: "2021-11-02T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (E-2358-0-DIA-PT8Y             )\\E-2358-0-DIA-PT8Y-icon.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2021-09-14T00:00:00",
    IsMounted: "0",
    HoldDate: "2021-04-05T00:00:00",
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30403749,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "10.70",
    Description:
      "Legacy Kiss Earrings with White and Yellow Diamonds in Platinum and 18k Yellow Gold",
    CenterShape: null,
    MemoNbr: "M0-309580",
    Maker: "Kwiat",
    StatusRefNbr: "M0-309580",
    SoldDiscountCode: "",
    HoldCustomerName: "Rebecca Selva",
    SearchType: "J",
    Diameter: "",
    ElementWidth: "14.69 MM",
    RingSize: null,
    VendorID: "C000008346",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "1",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "E-2358-0-DIA-PT8Y",
    WebImage1: "2358_0_PLAT18KY_1.jpg",
    WebImage2: null,
    StatusDate: "2021-11-01T00:00:00",
    LongDescription: "",
    DiamondCarats: "38.25",
    WebImage5: null,
    Length: "",
    RFIDValue: null,
    WebImage3: null,
    WebImage4: "2358_0_PLAT18KY-4-FS-1500.jpg",
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.37",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: null,
    Provenance: null,
    SerialLastModifiedDate: "2021-11-02T00:00:00",
    ReportJpgUrls:
      " https://cdn.kwiat.com/kwiat/certs-jpgs/2145985774.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/2155133334.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/2155918235.jpg|https://cdn.kwiat.com/kwiat/certs-jpgs/5161307100.jpg",
    SemiMount: false,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "Platinum and 18K yellow gold",
    StatusAmount: "1350000.00",
    StatusCustomer: "Kwiat Wynn",
    CenterClarity: null,
    FileIdNames: null,
    Invid: 74579,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: "EF",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "42.46 MM",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: null,
    MountingWholesale: "1269150.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV74579&https://cdn4.kwiat.com/source-images/web/product/2358_0_PLAT18KY_1-product@2x.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: "Hold for Oscars nomination consideration",
    SerialNumber: "114278",
    StatusCustomerID: "C000028075",
    WholesalePrice: "1350000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=6153CD8C-F751-4E78-98D7-D39A6F803686",
    Collection: "Legacy",
    HoldBy: "Selva, Rebecca",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MEMO",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "LAXHSG",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls:
      " https://cdn.kwiat.com/kwiat/certs-pdfs/2145985774.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/2155133334.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/2155918235.pdf|https://cdn.kwiat.com/kwiat/certs-pdfs/5161307100.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: "Earring - Post",
    IsFLRound: "0",
    WidthOD: "",
    Height: null,
    InStockDate: "2015-04-28T00:00:00",
    MemoOutCustomer: "Kwiat Wynn",
    SubCollection: null,
    HoldNoteID: 9655,
    IsSemimount: "0",
    dimensionTakenBy: "Urena, Genesis",
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "VS1-SI2",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.65",
    IsRtv: "0",
    SerialSort: "114278",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Kwiat Collections",
    InStockCost: "988753.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=1397FF3A-D28E-4E96-AE4A-7A6AE9362F6E",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2021-12-10",
    UsrMountedStockNbr: null,
    RetailPrice: "2200000.000000",
    LargeImageName:
      "Inventory ID / SKU (R-43072-FL-0-0                )\\R-43072-FL-0-0-large.jpg",
    RetailPriceCode: "IXFXXXX",
    ItemType: "Ring",
    ConsignmentCost: "1100000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 101284,
    ShortDescription: "7.33ct Oval Burma Ruby and Diamond Cluster Ring",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: null,
    SerialStatus: "MADAVE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "2.00",
    WholesalePriceCode: "BGHSFXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "046643",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: "2021-12-15T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (R-43072-FL-0-0                )\\R-43072-FL-0-0-icon.jpg",
    IsSold: "0",
    VendorRefNbr: "V161",
    IsStuds: "0",
    PricingDate: "2021-12-15T00:00:00",
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30318219,
    RetailSoldCustomer: null,
    FlushBand: "0",
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: "7.90",
    Description: "7.33ct Oval Burma Ruby and Diamond Cluster Ring",
    CenterShape: null,
    MemoNbr: null,
    Maker: null,
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 6.75",
    VendorID: "C000029600",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "R-43072-FL-0-0",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-12-18T00:00:00",
    LongDescription:
      "Oval Ruby 7.327cts. SSEF Report 81327 states the origin is Burma with no indications of heat treatment.\n13 Round Diamonds appx. 4cts, appx. G-I/VS.\nPlatinum Mounting with French Hallmarks.",
    DiamondCarats: null,
    WebImage5: null,
    Length: "",
    RFIDValue: "000074143415255844062446",
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "0",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.25",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Gemstone Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-12-18T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: null,
    SoldMarginCode: "",
    Metal: "Platinum",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: null,
    FileIdNames:
      " BRN30055C99463A_021357.pdf:EB6384CF-CCE1-4938-8A99-0D5197ACC62B",
    Invid: 101284,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: null,
    Period: null,
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "6.75",
    MountingWholesale: null,
    webProductURL:
      "https://www.fredleighton.com/?opt_product_id=SERFL43072&acu=true&image=https://cdn4.kwiat.com/source-images/large/R-43072-FL-0-0-large.jpg&desc=Serial FL43072&price=&serial=FL43072&inv=101284&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Fred Leighton",
    HoldText: null,
    SerialNumber: "FL43072",
    StatusCustomerID: null,
    WholesalePrice: "1375000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=64B441E0-D8AB-4CDC-8395-3B6EBE194D31",
    Collection: "Vintage Jewelry",
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MADAVE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "BXFXXXX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "2.70 MM",
    Height: null,
    InStockDate: "2021-12-10T00:00:00",
    MemoOutCustomer: null,
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: "Wright, Lara",
    ColorComment: null,
    CircaDate: null,
    Vendor: "Trafalgar Jewellers",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.60",
    IsRtv: "0",
    SerialSort: "43072",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Vintage Jewelry",
    InStockCost: "1100000.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=12F68F62-F6B2-41B7-826C-FA878BCF3BED",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2015-05-19",
    UsrMountedStockNbr: null,
    RetailPrice: "1903000.000000",
    LargeImageName: "114962-still.jpg",
    RetailPriceCode: "BLFGFXX",
    ItemType: "Ring",
    ConsignmentCost: "500000.01",
    RetailSoldCustomerID: null,
    CenterStoneNbr: "D48038",
    InventoryID: 78911,
    ShortDescription:
      "The Kwiat Setting Bluish Green Radiant Cut Diamond Engagement Ring with a Pink Diamond Halo in 18K Rose Gold, Radiant Center Diamond of 2.08 Carats, SI1 Clarity",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "5038.98",
    SerialStatus: "MADAVE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: "18KP",
    ProfitMargin: null,
    TotalMarkup: "3.77",
    WholesalePriceCode: "BGSLSFX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: "2021-06-30T00:00:00",
    HoldNoteNbr: "HN-00011126",
    PurchaseRefNumber: null,
    StyleStatus: "Active",
    BangleSize: null,
    DimensionTakenDate: null,
    VendorRefDisplay: "",
    CenterCaratWeight: "2.08",
    IconImageName: "114962-still.jpg",
    IsSold: "0",
    VendorRefNbr: null,
    IsStuds: "0",
    PricingDate: "2019-04-14T00:00:00",
    IsMounted: "0",
    HoldDate: "2021-05-26T00:00:00",
    GemstoneType: "Diamond",
    operationType: "Update",
    JewDataId: 30438630,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink:
      "https://cdn.kwiat.com/kwiat/v360/imaged/114962/114962.html?sm=0&btn=0&z=0&sv=1&zoomslide=0&isFullLoad=1",
    ItemClass: "Manufactured Jewelry",
    FinishedDWT: "1.87",
    Description:
      "The Kwiat Setting Bluish Green Radiant Cut Diamond Engagement Ring with a Pink Diamond Halo in 18K Rose Gold",
    CenterShape: "Radiant",
    MemoNbr: null,
    Maker: "Kwiat",
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 5.75 full",
    VendorID: "C000008346",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "1",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-17751MRB-0-DIA-18KP",
    WebImage1: "17751MRB_0_18KP_1.jpg",
    WebImage2: "17751MRB_0_18KP_2.jpg",
    StatusDate: "2021-12-17T00:00:00",
    LongDescription: "",
    DiamondCarats: "2.08",
    WebImage5: null,
    Length: "",
    RFIDValue: "000074404530100117896332",
    WebImage3: null,
    WebImage4: "17751MRB_0_18KP-4-FS-1500.jpg",
    CenterLabReportNbr: null,
    IsKwiat: "1",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.40",
    EditorialVideo: "dbf67e8c86b226bdcc9be171479009a7",
    SoldPrice: null,
    ItemSubtype: "Rings - Diamond Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-12-21T00:00:00",
    ReportJpgUrls: " https://cdn.kwiat.com/kwiat/certs-jpgs/1149204236.jpg",
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: "USA",
    SoldMarginCode: "",
    Metal: "18 karat rose gold",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: "SI1",
    FileIdNames: " 114962-still.jpg:0595785B-98E9-4352-A3AE-804591155DFB",
    Invid: 78911,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: null,
    Color: "Mixed Colors",
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "5.875",
    MountingWholesale: "7050.00",
    webProductURL:
      "https://kwiat.com/?opt_product_id=INV78911&https://cdn4.kwiat.com/source-images/web/product/17751MRB_0_18KP_1-product@2x.jpg",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Kwiat",
    HoldText: "HOLD for Russell",
    SerialNumber: "114962",
    StatusCustomerID: null,
    WholesalePrice: "1359500.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=0595785B-98E9-4352-A3AE-804591155DFB",
    Collection: "Bridal",
    HoldBy: "Tran, Nhan",
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MADAVE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "SFSFGA",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: " https://cdn.kwiat.com/kwiat/certs-pdfs/1149204236.pdf",
    ConsignmentItem: "0",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "1.60 MM",
    Height: null,
    InStockDate: "2015-05-19T00:00:00",
    MemoOutCustomer: null,
    SubCollection: "Bridal - The Kwiat Setting",
    HoldNoteID: 11180,
    IsSemimount: "0",
    dimensionTakenBy: null,
    ColorComment: null,
    CircaDate: null,
    Vendor: "Kwiat Enterprises, LLC",
    Clarity: "VS2-SI1",
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.40",
    IsRtv: "0",
    SerialSort: "114962",
    SideStoneCaratWeight: "0.60",
    DiamondDetails: "70BR 1RA",
    ColorCarats: null,
    InventoryCategory: "Solitaire Rings",
    InStockCost: "505038.99",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=0595785B-98E9-4352-A3AE-804591155DFB",
  },
  {
    SoldDate: null,
    ItemStatus: "Active",
    NewInStockDate: "2018-11-20",
    UsrMountedStockNbr: null,
    RetailPrice: "1700000.000000",
    LargeImageName:
      "Inventory ID / SKU (F-40133-FL-0-0                )\\F-40133-FL-0-0-FL40133-large.jpg",
    RetailPriceCode: "BHFXXXX",
    ItemType: "Ring",
    ConsignmentCost: "850000.00",
    RetailSoldCustomerID: null,
    CenterStoneNbr: null,
    InventoryID: 89400,
    ShortDescription: "4.10ct Oval Burma Ruby and Diamond Ring",
    StyleVideoLink: null,
    CenterOrigin: null,
    OwnCost: "0.00",
    SerialStatus: "MADAVE",
    DropLength: "",
    Milgrain: null,
    SoldCustomerID: null,
    SoldPriceCode: "",
    MemoOutCustomerID: null,
    IsTiara: "0",
    MetalNote: null,
    ProfitMargin: null,
    TotalMarkup: "2.00",
    WholesalePriceCode: "BFSFXXX",
    AdjustmentNbr: null,
    index: "kwfl-acumatica-catalog-v7-prod-jewelryserial2",
    PricingMode: "1",
    ReleaseDate: null,
    HoldNoteNbr: null,
    PurchaseRefNumber: "000502",
    StyleStatus: null,
    BangleSize: null,
    DimensionTakenDate: "2021-12-03T00:00:00",
    VendorRefDisplay: "",
    CenterCaratWeight: null,
    IconImageName:
      "Inventory ID / SKU (F-40133-FL-0-0                )\\F-40133-FL-0-0-FL40133-icon.jpg",
    IsSold: "0",
    VendorRefNbr: "NVP11",
    IsStuds: "0",
    PricingDate: null,
    IsMounted: "0",
    HoldDate: null,
    GemstoneType: null,
    operationType: "Update",
    JewDataId: 30463378,
    RetailSoldCustomer: null,
    FlushBand: null,
    SerialVideoLink: null,
    ItemClass: "Purchased Jewelry",
    FinishedDWT: "3.75",
    Description: "4.10ct Oval Burma Ruby and Diamond Ring",
    CenterShape: null,
    MemoNbr: null,
    Maker: null,
    StatusRefNbr: null,
    SoldDiscountCode: "",
    HoldCustomerName: null,
    SearchType: "J",
    Diameter: "",
    ElementWidth: "",
    RingSize: "Ring size 6.5",
    VendorID: "C000001564",
    CenterColor: null,
    EngraveLimit: null,
    hasWebImage: "0",
    SemimountType: null,
    WRShape: null,
    transformType: "JewelrySerial",
    StyleNumber: "F-40133-FL-0-0",
    WebImage1: null,
    WebImage2: null,
    StatusDate: "2021-11-03T00:00:00",
    LongDescription:
      "(GUBELIN) NO HEAT BURMA\r\n1PC 4.10CTS\r\n2 DIAM 2.02CTS\r\n( GIA ) D/VS1 & D/VVS2\r\n18K W/G 4.62GM",
    DiamondCarats: null,
    WebImage5: null,
    Length: "",
    RFIDValue: "000074663571519244112770",
    WebImage3: null,
    WebImage4: null,
    CenterLabReportNbr: null,
    IsKwiat: "0",
    Feeds: null,
    IsKWCushion: "0",
    WholesaleMarkup: "1.24",
    EditorialVideo: null,
    SoldPrice: null,
    ItemSubtype: "Rings - Gemstone Ring",
    Provenance: null,
    SerialLastModifiedDate: "2021-12-03T00:00:00",
    ReportJpgUrls: null,
    SemiMount: null,
    SoldCustomer: null,
    CountryOfOrigin: null,
    SoldMarginCode: "",
    Metal: "18 karat white gold",
    StatusAmount: null,
    StatusCustomer: null,
    CenterClarity: null,
    FileIdNames: null,
    Invid: 89400,
    MountedInventoryID: null,
    SoldMargin: null,
    DimensionTaken: "1",
    Color: null,
    Period: "Contemporary",
    CaseWidth: null,
    StoneOrientation: null,
    ElementLength: "",
    IsAshoka: "0",
    SettingType: null,
    RingSizeValue: "6.5",
    MountingWholesale: null,
    webProductURL:
      "https://www.fredleighton.com/?opt_product_id=SERFL40133&acu=true&image=https://cdn4.kwiat.com/source-images/large/F-40133-FL-0-0-FL40133-large.jpg&desc=18 karat white gold Engagement Ring Style FL40133&price=1701000&serial=FL40133&inv=89400&video=",
    SizingNotes: null,
    isOpenJob: "0",
    Brand: "Fred Leighton",
    HoldText: null,
    SerialNumber: "FL40133",
    StatusCustomerID: null,
    WholesalePrice: "1050000.000000",
    IconImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=8BCB1C58-F0C3-4C37-B72C-17965A6F871F",
    Collection: null,
    HoldBy: null,
    CaseLength: null,
    SoldRefNumber: null,
    RFIDOldValue2: null,
    Warehouse: "MADAVE",
    RFIDOldValue1: null,
    SoldDiscount: null,
    IsVirtual: "0",
    InStockCostCode: "ASFXXX",
    CenterEnhancement: null,
    RTVDate: null,
    ReportPdfUrls: null,
    ConsignmentItem: "1",
    MountedInventoryDBID: null,
    ClosureType: null,
    IsFLRound: "0",
    WidthOD: "3.41 MM",
    Height: null,
    InStockDate: "2018-11-20T00:00:00",
    MemoOutCustomer: null,
    SubCollection: null,
    HoldNoteID: null,
    IsSemimount: "0",
    dimensionTakenBy: "Urena, Genesis",
    ColorComment: null,
    CircaDate: null,
    Vendor: "Universal Gems",
    Clarity: null,
    IsCom: "0",
    CenterCut: null,
    RetailMarkup: "1.62",
    IsRtv: "0",
    SerialSort: "40133",
    SideStoneCaratWeight: null,
    DiamondDetails: null,
    ColorCarats: null,
    InventoryCategory: "Vintage Jewelry",
    InStockCost: "850000.00",
    IsPhantom: "0",
    PartwayEternity: null,
    LargeImageFileID:
      "https://kim.kwiat.com/Frames/GetFile.ashx?fileID=CFDBD345-C5DA-4270-A5C7-3C6C0E0AACCA",
  },
];
