import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../../HOC_Components/MultiDropdownListWithFocus";

class StyleStatus extends Component {
  render() {
    return (
      <MultiDropdownList
        className="form_field"
        title="Style Status"
        componentId="Style_Status"
        dataField={"StyleStatus.keyword"}
        size={100}
        showCount={false}
        showSearch={true}
        sortBy="asc"
        renderListItem={(label) => (
          <div>{label !== "NULL" ? label : "None"}</div>
        )}
      />
    );
  }
}

export default MultiDropdownListWithFocus(StyleStatus);
