import React, { Component, useEffect, useState } from "react";
import { Carousel } from "bootstrap";
import ImageGallery from "react-image-gallery";
import currencyFormatter from "currency-formatter";
import { Accordion, Modal, Table } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { useParams } from "react-router-dom";
import {
  JewelrySerialApp,
  DiamondSerialApp,
  GemstoneSerialApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  BaseURL,
  AppbaseHoldNotesApp,
  holdNoteApi,
  holdNoteApiKey,
} from "../../utils/constants";
import Appbase from "appbase-js";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleSingleView,
  addToCart,
  setBasketFormInput,
  toggleIframeModal,
  toggleBasket,
  toggleLoader,
} from "../actions";
import { useSwipeable } from "react-swipeable";
import { FieldData, DiamondFieldData, GemStoneFieldData } from "./FieldData";
import Email from "../../assets/icons/Email.png";
import Print from "../../assets/icons/Print.png";
import Basket from "../../assets/icons/basket_white.png";
import PreviewEmailModal from "../Basket/PDF/PreviewEmailModal";
import PDFModal from "../Basket/PDF/PDFModal";
import ChooseLayoutModal from "../Basket/ChooseLayoutModal";
import HoldNotesArchievedModal from "../OtherComponents/HoldNotesArchievedModal";
import HoldNoteCreateModal from "../OtherComponents/HoldNoteCreateModal";
import {
  fetchApiData,
  getHoldNotesData,
} from "../OtherComponents/holdNotesApiData";
import axios from "axios";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

export default function SingleItem(props) {
  let {
    handleItemToView,
    toggleSingleItem,
    addItemToBasket,
    isValueEmpty,
    isMultipleValueEmpty,
    handleEmail,
    showChooseLayout,
    handleIncludeGIA,
    handleSetCover,
    handleStockPopUpModal,
  } = props;
  let { id } = useParams();

  const { show, item } = useSelector((state) => state.singleViewModal);
  const basket = useSelector((state) => state.basket);
  const basketInputObj = useSelector((state) => state.basketInputChange);
  const showWholesale = useSelector(
    (state) => state.basketInputChange.showWholesale
  );
  const feedRestrictionsData = useSelector((state) => state.apiData.feedsData);
  const jewelryRestrictionData = useSelector(
    (state) => state.apiData.jewelryHoldData
  );
  const employeeData = useSelector((state) => state.apiData.empData);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    item: item,
    showHoldNotesModal: false,
    showHoldNotesEditModal: false,
    holdNotesData: "",
    showHoldNoteCreateModal: false,
    customers: "",
    holdNoteItem: "",
  });

  const handleItemHoldNoteUpdate = async (data) => {
    // console.log({ data });
    // this.setState((prevState) => ({
    //   results: prevState.results.map((result) => {
    //     if (result.SerialNumber === data.SerialNumber) {
    //       // console.log("Inside serial number match.");
    //       return data;
    //     } else {
    //       return result;
    //     }
    //   }),
    // }));
    setState((prevState) => {
      return { ...prevState, item: data };
    });
  };

  const handleHoldNoteArchive = async (res) => {
    let result = window.confirm("Confirm Hold Note Archieve ?");
    if (result) {
      dispatch(
        toggleLoader({
          isLoading: true,
        })
      );
      let payload = {
        data: {
          SerialNbr: res.SerialNumber,
          InventoryID: res.InventoryID,
        },
      };
      var resp = await axios
        .post(holdNoteApi + "archiveholdnote", payload, {
          headers: {
            "x-api-key": holdNoteApiKey,
          },
        })
        .catch((error) => {
          console.log("error :", error);
          // toast.error("Error while saving !", {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          //   pauseOnHover: false,
          // });
          // toggleLoader({
          //   isLoading: false,
          // });
        });
      // console.log("resp: ", resp);

      if (
        resp &&
        resp.data &&
        resp.data.statusCode &&
        resp.data.statusCode === 200
      ) {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
        // this.setState((prevState) => ({
        //   results: prevState.results.map((result) => {
        //     if (result.SerialNumber === res.SerialNumber) {
        //       return {
        //         ...result,
        //         // HoldBy: null,
        //         HoldCustomerId: null,
        //         HoldCustomerName: null,
        //         HoldDate: null,
        //         HoldEnteredByID: null,
        //         HoldEnteredByName: null,
        //         HoldEnteredForID: null,
        //         HoldEnteredForName: null,
        //         HoldNoteID: null,
        //         HoldNoteNbr: null,
        //         HoldText: null,
        //         ReleaseDate: null,
        //         IsHoldSold: null,
        //       };
        //     } else {
        //       return result;
        //     }
        //   }),
        // }));
        setState((prevState) => {
          return {
            ...prevState,
            item: {
              ...prevState.item,
              // HoldBy: null,
              HoldCustomerId: null,
              HoldCustomerName: null,
              HoldDate: null,
              HoldEnteredByID: null,
              HoldEnteredByName: null,
              HoldEnteredForID: null,
              HoldEnteredForName: null,
              HoldNoteID: null,
              HoldNoteNbr: null,
              HoldText: null,
              ReleaseDate: null,
              IsHoldSold: null,
            },
          };
        });
      } else {
        dispatch(
          toggleLoader({
            isLoading: false,
          })
        );
      }
    }
  };

  const handleHoldNotesModals = async (id, value, type, res) => {
    setState((prevState) => {
      return { ...prevState, holdNoteItem: res };
    });
    let response;
    if (id && value) {
      // await this.getHoldNotesData(id);
      response = await getHoldNotesData(id, AppbaseHoldNotesApp);
      setState((prevState) => {
        return { ...prevState, holdNotesData: response };
      });
    }
    if (
      value &&
      type === "archivedHoldNotes" &&
      (!response ||
        response.length === 0 ||
        response.filter(
          (data) => data._source.IsArchived && data._source.IsArchived === "1"
        ).length === 0)
    ) {
      window.alert("There are no Hold Notes.");
      return;
    }
    setState((prevState) => {
      return {
        ...prevState,
        ...(type === "archivedHoldNotes" ? { showHoldNotesModal: value } : {}),
        ...(type === "archivedHoldNotes" && !value
          ? { holdNotesData: "" }
          : {}),
        ...(type === "createHoldNotes"
          ? { showHoldNoteCreateModal: value }
          : {}),
        ...(!value
          ? {
              showHoldNotesEditModal: false,
              holdNotesData: "",
              holdNoteItem: "",
            }
          : {}),
      };
    });

    // if (!value) {
    //   this.setState({ holdNotesData: "" });
    // }
  };

  const handleImageGallery = (res) => {
    // console.log("inside handleImageGallery");
    var imgArr = [];
    if (res) {
      function showShapeImage(shape) {
        let imageurl =
          "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
          shape +
          ".jpg";
        return imageurl;
      }
      function showWebImage(img) {
        var src = "https://cdn4.kwiat.com/source-images/web/original/" + img;
        return src;
      }
      function showimage(image) {
        let img,
          str = "";
        if (image && image != null) {
          let searchimage;
          searchimage = image;
          str = searchimage.split("\\");
          searchimage = str[str.length - 1];
          img = "https://cdn.kwiat.com/source-images/large/" + searchimage;
        } else {
          img = "";
        }
        return img;
      }
      const webImgName = (img) => img.replace(/ /g, "");
      const largeImgName = (img) => {
        var str = img.split("\\");
        return str[str.length - 1];
      };

      if (res.LargeImageName) {
        imgArr.push({
          original: showimage(res.LargeImageName),
          thumbnail: showimage(res.LargeImageName),
          imgName: largeImgName(res.LargeImageName),
        });
      }
      if (res.Shape) {
        imgArr.push({
          original: showShapeImage(res.Shape),
          thumbnail: showShapeImage(res.Shape),
          imgName: res.shape,
        });
      }
      if (res.EditorialVideo) {
        imgArr.push({
          thumbnail:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          original:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          embedUrl: res.EditorialVideo,
          // description: "Render custom slides (such as videos)",
          renderItem: renderVideo.bind(this),
        });
      }
      if (res.SerialVideoLink) {
        imgArr.push({
          thumbnail:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          original:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          embedUrl: res.SerialVideoLink,
          // description: "Render custom slides (such as videos)",
          renderItem: renderVideo.bind(this),
        });
      }
      if (res.StyleVideoLink) {
        imgArr.push({
          thumbnail:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          original:
            "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg",
          embedUrl: res.StyleVideoLink,
          // description: "Render custom slides (such as videos)",
          renderItem: renderVideo.bind(this),
        });
      }

      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field]),
          });
        }
      }
    }
    // console.log("imgArr : ", imgArr);
    return imgArr;
    // this.setState({
    //   imgArr: imgArr,
    // });
  };
  const renderVideo = (item) => {
    return (
      <div className="video-wrapper">
        {/* <a
          className="close-video"
          onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
        ></a> */}
        <iframe
          // width="350"
          // height="300"
          src={`${item.embedUrl}?autoplay=1`}
          // src={`https://iframe.videodelivery.net/${item.embedUrl}`}
          style={{ border: "none" }}
          allowFullScreen="true"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        ></iframe>
        {/* <video autoPlay>
          <source src={item.embedUrl} type="video/mp4" />
        </video> */}
      </div>
    );
  };
  const onModalHide = () => {
    dispatch(toggleSingleView({ show: false, item: {} }));
    setState((prevState) => {
      return { ...prevState, item: "" };
    });
  };
  const handleReports = (type) => {
    // let { resultData } = this.state;
    if (type === "jpg") {
      if (item.ReportJpgUrls) {
        // console.log(
        //   "jpg",
        //   item.ReportJpgUrls.split("|").map((value) =>
        //     value.replace(" ", "")
        //   )
        // );
        return item.ReportJpgUrls.split("|").map((value) =>
          value.replace(" ", "")
        );
      }
      return [];
    }
    if (type === "pdf") {
      if (item.ReportPdfUrls) {
        // console.log(
        //   "pdf",
        //   item.ReportPdfUrls.split("|").map((value) =>
        //     value.replace(" ", "")
        //   )
        // );
        return item.ReportPdfUrls.split("|").map((value) =>
          value.replace(" ", "")
        );
      }
      return [];
    }

    // let fileIdNames = res.FileIdNames;
    // let fileData = fileIdNames.split("|");
    // // console.log("fileData: ", fileData);
    // if (fileData.length === 1) {
    //   let fileID = fileData[0].slice(fileData[0].indexOf(":") + 1);
    //   // console.log("fileID: ", fileID);
    //   window
    //     .open(BaseURL + "/Frames/GetFile.ashx?fileID=" + fileID, "_blank")
    //     .focus();
    // } else {
    //   this.setState({ fileData, showFileModal: true });
    // }
  };

  // useEffect(() => {
  //   getItem();
  // }, [id]);
  useEffect(() => {
    setState((prevState) => {
      return { ...prevState, item: item };
    });
  }, [item]);
  return (
    // <label>{id}</label>

    <Modal
      animation={false}
      autoFocus={false}
      enforceFocus={false}
      className="single_item_modal"
      centered="true"
      size="lg"
      show={show}
      onHide={() => onModalHide()}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {!basket.show && (
          <img
            className="basket"
            src={Basket}
            onClick={() => dispatch(toggleBasket({ show: true }))}
          ></img>
        )}
        <div className="single_item_container">
          <div className="item_short_detail">
            <div className="image_container">
              <ImageGallery
                items={handleImageGallery(state.item)}
                showFullscreenButton={false}
                showPlayButton={false}
                showNav={false}
                onErrorImageURL="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                showThumbnails={false}
                showBullets={true}
              />
            </div>
            <span className="other_item_details">
              {" "}
              {state.item.IsSold === "1"
                ? `Sold ${
                    state.item.StatusCustomer
                      ? `- ${state.item.StatusCustomer}`
                      : ``
                  }`
                : ""}
              {state.item.IsRtv === "1"
                ? `RTV ${
                    state.item.StatusCustomer
                      ? `- ${state.item.StatusCustomer}`
                      : ``
                  }`
                : ""}
              {state.item.SerialStatus === "In Production"
                ? "In Production"
                : ""}
              {state.item.IsSemimount === "1" ? "Semimount" : ""}
              {state.item.SerialStatus === "Adjusted Out" ? "Adjusted Out" : ""}
              {state.item.Warehouse &&
                `${state.item.Warehouse} ${
                  state.item.StatusCustomer
                    ? `- ${state.item.StatusCustomer}`
                    : ``
                }`}
              {state.item.IsMounted === "1" && state.item.IsSold !== "1"
                ? " mounted"
                : ""}
              {state.item.isOpenJob === "1" && state.item.PONumber !== null
                ? `ON ORDER`
                : ""}
              {state.item.isOpenJob === "1" && state.item.PONumber === null
                ? `STOCK CREATE`
                : ""}
              {state.item.IsVirtual === "1" && " Virtual"}
              {state.item.IsPhantom === "1" && " Phantom"}
            </span>
            <h6>
              {state.item.SerialNumber && state.item.StyleNumber
                ? `${state.item.SerialNumber} | ${state.item.StyleNumber}`
                : state.item.SerialNumber
                ? state.item.SerialNumber
                : state.item.StyleNumber
                ? state.item.StyleNumber
                : ``}
                {state.item.dwebb_vegas === "1" && <span>Kwiat WYNN ONLY</span>}
            </h6>
            <div className="single_item_description">
              {state.item.Description}
               {/*console.log(state.item.IsRough)*/}
            </div>
            {state.item.RetailPrice ||
            (state.item.WholesalePrice && showWholesale) ? (
              <div
                className="single_item_price"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!basket.show) {
                    handleStockPopUpModal(true, state.item);
                  }
                }}
              >
                {state.item.RetailPrice && (
                  <label>
                    {currencyFormatter.format(`${state.item.RetailPrice}`, {
                      code: "USD",
                      precision: 0,
                    }) || ""}
                    <span> (R)</span>
                  </label>
                )}{" "}
                {state.item.WholesalePrice && showWholesale && (
                  <label>
                    {isMultipleValueEmpty(state.item, "WholesalePrice")}{" "}
                    <span>(W)</span>
                  </label>
                )}
              </div>
            ) : (
              <></>
            )}
            {/* {state.item.WholesalePrice && showWholesale && (
              <div className="single_item_price">
                <label>
                  {isMultipleValueEmpty(state.item, "WholesalePrice")}{" "}
                  <span>(W)</span>
                </label>
              </div>
            )} */}
          </div>

          <div className="single_item_details">
            <Accordion
              defaultActiveKey={["0", "1", "2", "3", "4", "5", "6", "7", "8"]}
              alwaysOpen
            >
              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.GeneralData)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="0">
                  <AccordionHeader>GENERAL INFORMATION</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.GeneralData).map(
                          (key, Index) => {
                            if (state.item[key]) {
                              return (
                                <tr key={key}>
                                  <td>
                                    <li>
                                      {" "}
                                      {FieldData.GeneralData[key].label}{" "}
                                    </li>
                                  </td>
                                  <td>{state.item[key]}</td>
                                </tr>
                              );
                            } else return <></>;
                          }
                        )}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}
              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.Description)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="1">
                  <AccordionHeader>DESCRIPTION</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.Description).map(
                          (key, Index) => {
                            if (state.item[key]) {
                              return (
                                <tr key={key}>
                                  <td>
                                    <li>
                                      {" "}
                                      {FieldData.Description[key].label}{" "}
                                    </li>
                                  </td>
                                  <td>{state.item[key]}</td>
                                </tr>
                              );
                            } else return <></>;
                          }
                        )}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}
              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.RingDetail)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="2">
                  <AccordionHeader>RING DETAIL</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.RingDetail).map((key, Index) => {
                          if (state.item[key]) {
                            return (
                              <tr key={key}>
                                <td>
                                  <li> {FieldData.RingDetail[key].label} </li>
                                </td>
                                <td>{state.item[key]}</td>
                              </tr>
                            );
                          } else return <></>;
                        })}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}
              {/* {Object.keys(FieldData.DiamondDetail)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="3">
                  <AccordionHeader>DIAMOND DETAIL</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.DiamondDetail).map(
                          (key, Index) => {
                            if (state.item[key]) {
                              return (
                                <tr key={key}>
                                  <td>
                                    <li>
                                      {" "}
                                      {FieldData.DiamondDetail[key].label}{" "}
                                    </li>
                                  </td>
                                  <td>{state.item[key]}</td>
                                </tr>
                              );
                            } else return <></>;
                          }
                        )}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )} */}

              {state.item.transformType !== "DiamondSerial" &&
              state.item.transformType !== "GemstoneSerial" ? (
                Object.keys(FieldData.DiamondDetail)
                  .map((key, Index) => state.item[key])
                  .filter((value) => ![undefined, null, ""].includes(value))
                  .length !== 0 ? (
                  <AccordionItem eventKey="3">
                    <AccordionHeader>DIAMOND DETAIL</AccordionHeader>
                    <AccordionBody>
                      <Table>
                        <tbody>
                          {Object.keys(FieldData.DiamondDetail).map(
                            (key, Index) => {
                              if (state.item[key]) {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <li>
                                        {FieldData.DiamondDetail[key].label}:
                                      </li>
                                    </td>
                                    <td>{state.item[key]}</td>
                                  </tr>
                                );
                              } else return <></>;
                            }
                          )}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                ) : (
                  <></>
                )
              ) : state.item.transformType === "DiamondSerial" ? (
                Object.keys(DiamondFieldData.DiamondDetail)
                  .map((key, Index) => state.item[key])
                  .filter((value) => ![undefined, null, ""].includes(value))
                  .length !== 0 ? (
                  <AccordionItem eventKey="3">
                    <AccordionHeader>DIAMOND DETAIL</AccordionHeader>
                    <AccordionBody>
                      <Table>
                        <tbody>
                          {Object.keys(DiamondFieldData.DiamondDetail).map(
                            (key, Index) => {
                              if (state.item[key] && key !== "StoneCulet") {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <li>
                                        {
                                          DiamondFieldData.DiamondDetail[key]
                                            .label
                                        }
                                        :
                                      </li>
                                    </td>
                                    <td>
                                      {key === "DiamondCaratWeight"
                                        ? `${Number(state.item[key]).toFixed(
                                            2
                                          )}cts`
                                        : key === "GIAReportDate"
                                        ? moment(
                                            new Date(`${state.item[key]}`)
                                          ).format("MM/DD/YYYY")
                                        : state.item[key]}
                                    </td>
                                  </tr>
                                );
                              } else if (key === "StoneCulet") {
                                return (
                                  <>
                                    {state.item[key] && (
                                      <tr key={key}>
                                        <td>
                                          <li>
                                            {
                                              DiamondFieldData.DiamondDetail[
                                                key
                                              ].label
                                            }
                                            :
                                          </li>
                                        </td>
                                        <td>
                                          {key === "DiamondCaratWeight"
                                            ? `${Number(
                                                state.item[key]
                                              ).toFixed(2)}cts`
                                            : key === "GIAReportDate"
                                            ? moment(
                                                new Date(`${state.item[key]}`)
                                              ).format("MM/DD/YYYY")
                                            : state.item[key]}
                                        </td>
                                      </tr>
                                    )}
                                    {state.item.Length ||
                                    state.item.Depth ||
                                    state.item.Width ? (
                                      <tr>
                                        <td>
                                          {/* {DiamondFieldData.DiamondDetail[key].label} */}
                                          <li>Measurements:</li>
                                        </td>
                                        <td>
                                          {state.item.Length &&
                                            state.item.Length}{" "}
                                          {state.item.Length ? "x" : ""}{" "}
                                          {state.item.Width}{" "}
                                          {state.item.Width && state.item.Depth
                                            ? "x"
                                            : ""}{" "}
                                          {state.item.Depth}
                                        </td>
                                      </tr>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              } else return <></>;
                            }
                          )}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                ) : (
                  <></>
                )
              ) : state.item.transformType === "GemstoneSerial" ? (
                Object.keys(GemStoneFieldData.DiamondDetail)
                  .map((key, Index) => state.item[key])
                  .filter((value) => ![undefined, null, ""].includes(value))
                  .length !== 0 ? (
                  <AccordionItem eventKey="3">
                    <AccordionHeader>GEMSTONE DETAIL</AccordionHeader>
                    <AccordionBody>
                      <Table>
                        <tbody>
                          {Object.keys(GemStoneFieldData.DiamondDetail).map(
                            (key, Index) => {
                              if (state.item[key]) {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <li>
                                        {
                                          GemStoneFieldData.DiamondDetail[key]
                                            .label
                                        }
                                        :
                                      </li>
                                    </td>
                                    <td>
                                      {key === "DiamondCaratWeight" ||
                                      key === "CaratWeight"
                                        ? `${Number(state.item[key]).toFixed(
                                            2
                                          )}cts`
                                        : key === "GIAReportDate"
                                        ? moment(
                                            new Date(`${state.item[key]}`)
                                          ).format("MM/DD/YYYY")
                                        : state.item[key]}
                                    </td>
                                  </tr>
                                );
                              } else return <></>;
                            }
                          )}
                          {Object.keys(GemStoneFieldData.Measurements)
                            .map((key, Index) => state.item[key])
                            .filter(
                              (value) => ![undefined, null, ""].includes(value)
                            ).length !== 0 ? (
                            <tr>
                              <td>
                                {/* {DiamondFieldData.DiamondDetail[key].label} */}
                                <li>Measurements:</li>
                              </td>
                              {state.item.Length ||
                              state.item.Depth ||
                              state.item.Width ? (
                                <td>
                                  {state.item.Length}{" "}
                                  {state.item.Length ? "x" : ""}{" "}
                                  {state.item.Width}{" "}
                                  {state.item.Width && state.item.Depth
                                    ? "x"
                                    : ""}{" "}
                                  {state.item.Depth}
                                </td>
                              ) : (
                                <></>
                              )}
                            </tr>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </AccordionBody>
                  </AccordionItem>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.ColorDetail)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="4">
                  <AccordionHeader>COLOR DETAILS</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.ColorDetail).map(
                          (key, Index) => {
                            if (state.item[key]) {
                              return (
                                <tr key={key}>
                                  <td>
                                    <li>
                                      {" "}
                                      {FieldData.ColorDetail[key].label}{" "}
                                    </li>
                                  </td>
                                  <td>{state.item[key]}</td>
                                </tr>
                              );
                            } else return <></>;
                          }
                        )}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}

              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.CenterInfo)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="5">
                  <AccordionHeader>CENTER INFO</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.CenterInfo).map((key, Index) => {
                          if (state.item[key]) {
                            return (
                              <tr key={key}>
                                <td>
                                  <li> {FieldData.CenterInfo[key].label} </li>
                                </td>
                                <td>{state.item[key]}</td>
                              </tr>
                            );
                          } else return <></>;
                        })}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}

              {(state.item.transformType === "JewelrySerial" ||
                !state.item.transformType) &&
              Object.keys(FieldData.Dimensions)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <AccordionItem eventKey="6">
                  <AccordionHeader>DIMENSIONS</AccordionHeader>
                  <AccordionBody>
                    <Table>
                      <tbody>
                        {Object.keys(FieldData.Dimensions).map((key, Index) => {
                          if (state.item[key]) {
                            return (
                              <tr key={key}>
                                <td>
                                  <li> {FieldData.Dimensions[key].label} </li>
                                </td>
                                <td>{state.item[key]}</td>
                              </tr>
                            );
                          } else return <></>;
                        })}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}

              {state.item.ReleaseDate ? (
                <AccordionItem eventKey="7">
                  <AccordionHeader>HOLD NOTES:</AccordionHeader>

                  <AccordionBody>
                    {basketInputObj.holdNotesAccess && (
                      <div class="hold_notes_actions">
                        {!state.item._id.toString().includes("JOB") &&
                          state.item.HoldNoteID && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip className="tooltip" id="Edit">
                                  Edit
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span
                                onClick={() => {
                                  setState((prevState) => {
                                    return {
                                      ...prevState,
                                      showHoldNotesEditModal: true,
                                    };
                                  });
                                  handleHoldNotesModals(
                                    "",
                                    true,
                                    "createHoldNotes",
                                    state.item
                                  );
                                }}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </OverlayTrigger>
                          )}
                        {!state.item._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Create">
                                Create
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() =>
                                handleHoldNotesModals(
                                  state.item.InventoryID.toString() +
                                    state.item.SerialNumber.toString(),
                                  true,
                                  "createHoldNotes",
                                  state.item
                                )
                              }
                            >
                              +
                            </span>
                          </OverlayTrigger>
                        )}
                        {state.item.HoldNoteID &&
                          !state.item._id.toString().includes("JOB") && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip className="tooltip" id="Archive">
                                  Archive
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span
                                onClick={() =>
                                  handleHoldNoteArchive(state.item)
                                }
                              >
                                x
                              </span>
                            </OverlayTrigger>
                          )}
                        {!state.item._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="View">
                                View
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={(e) =>
                                handleHoldNotesModals(
                                  state.item.InventoryID.toString() +
                                    state.item.SerialNumber.toString(),
                                  true,
                                  "archivedHoldNotes",
                                  ""
                                )
                              }
                            >
                              <i class="fa fa-history" aria-hidden="true"></i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                    <Table>
                      <tbody>
                        {state.item.HoldBy && (
                          <tr>
                            <td>
                              <li>Hold By</li>
                            </td>
                            <td>{state.item.HoldBy}</td>
                          </tr>
                        )}
                        {state.item.HoldCustomerName && (
                          <tr>
                            <td>
                              <li>Hold Customer Name</li>
                            </td>
                            <td>{state.item.HoldCustomerName}</td>
                          </tr>
                        )}
                        {state.item.HoldDate && (
                          <tr>
                            <td>
                              <li>Hold Date</li>
                            </td>
                            <td>
                              {state.item.HoldDate
                                ? moment(
                                    new Date(`${state.item.HoldDate}`)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </td>
                          </tr>
                        )}
                        {state.item.ReleaseDate && (
                          <tr>
                            <td>
                              <li>Release Date</li>
                            </td>
                            <td>
                              {state.item.ReleaseDate
                                ? moment(
                                    new Date(`${state.item.ReleaseDate}`)
                                  ).format("MM/DD/YYYY")
                                : ""}
                            </td>
                          </tr>
                        )}

                        {state.item.HoldText && (
                          <tr>
                            <td>
                              <li>Hold Text</li>
                            </td>
                            <td>{state.item.HoldText}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </AccordionBody>
                </AccordionItem>
              ) : basketInputObj.holdNotesAccess ? (
                <AccordionItem eventKey="7">
                  <AccordionHeader>HOLD NOTES:</AccordionHeader>

                  <AccordionBody>
                    {basketInputObj.holdNotesAccess && (
                      <div class="hold_notes_actions">
                        {!state.item._id.toString().includes("JOB") &&
                          state.item.HoldNoteID && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip className="tooltip" id="Edit">
                                  Edit
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span
                                onClick={() => {
                                  setState((prevState) => {
                                    return {
                                      ...prevState,
                                      showHoldNotesEditModal: true,
                                    };
                                  });
                                  handleHoldNotesModals(
                                    "",
                                    true,
                                    "createHoldNotes",
                                    state.item
                                  );
                                }}
                              >
                                <i
                                  class="fa fa-pencil-square-o"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </OverlayTrigger>
                          )}
                        {!state.item._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="Create">
                                Create
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={() =>
                                handleHoldNotesModals(
                                  state.item.InventoryID.toString() +
                                    state.item.SerialNumber.toString(),
                                  true,
                                  "createHoldNotes",
                                  state.item
                                )
                              }
                            >
                              +
                            </span>
                          </OverlayTrigger>
                        )}
                        {state.item.HoldNoteID &&
                          !state.item._id.toString().includes("JOB") && (
                            <OverlayTrigger
                              overlay={
                                <Tooltip className="tooltip" id="Archive">
                                  Archive
                                </Tooltip>
                              }
                              placement="top"
                            >
                              <span
                                onClick={() =>
                                  handleHoldNoteArchive(state.item)
                                }
                              >
                                x
                              </span>
                            </OverlayTrigger>
                          )}
                        {!state.item._id.toString().includes("JOB") && (
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="tooltip" id="View">
                                View
                              </Tooltip>
                            }
                            placement="top"
                          >
                            <span
                              onClick={(e) =>
                                handleHoldNotesModals(
                                  state.item.InventoryID.toString() +
                                    state.item.SerialNumber.toString(),
                                  true,
                                  "archivedHoldNotes",
                                  ""
                                )
                              }
                            >
                              <i class="fa fa-history" aria-hidden="true"></i>
                            </span>
                          </OverlayTrigger>
                        )}
                      </div>
                    )}
                  </AccordionBody>
                </AccordionItem>
              ) : (
                <></>
              )}
              {Object.keys(FieldData.CertifiedDiamondReports)
                .map((key, Index) => state.item[key])
                .filter((value) => ![undefined, null, ""].includes(value))
                .length !== 0 ? (
                <Accordion.Item eventKey="8">
                  <AccordionHeader>CERTIFIED DIAMOND REPORT</AccordionHeader>{" "}
                  <Accordion.Body className="item_report_img">
                    {/* {Object.keys(FieldData.CertifiedDiamondReports).map(
                        (key, Index) => {
                          if (resultData[key]) {
                            return (
                              <div className="field_data" key={key}>
                                <label>
                                  {FieldData.CertifiedDiamondReports[key].label}{" "}
                                  :
                                </label>
                                <label>{resultData[key]}</label>
                              </div>
                            );
                          } else return <></>;
                        }
                      )} */}
                    {handleReports("jpg").map((jpg, index) => (
                      <img
                        className="report_img"
                        src={jpg}
                        onClick={() =>
                          window.open(
                            this.handleReports("pdf")[index],
                            "_blank"
                          )
                        }
                      />
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )}
            </Accordion>
          </div>
          <div className="add_to_basket">
            <button
              onClick={() => {
                dispatch(
                  setBasketFormInput({
                    includePrice: false,
                    includeRetail: false,
                    includeWholesale: false,
                    includeLinks: "No",
                  })
                );

                handleSetCover("NoCover");
                handleIncludeGIA("No");
                showChooseLayout("Print", state.item);
              }}
            >
              <img src={Print}></img>
            </button>
            <button
              onClick={() => {
                dispatch(
                  setBasketFormInput({
                    includePrice: false,
                    includeRetail: false,
                    includeWholesale: false,
                    includeLinks: "No",
                  })
                );

                handleSetCover("NoCover");
                handleIncludeGIA("No");
                handleEmail(state.item);
                // onModalHide();
              }}
            >
              <img src={Email}></img>
            </button>
            <button
              onClick={() => dispatch(addToCart({ product: state.item }))}
            >
              Add to Basket
            </button>
          </div>
        </div>
        <HoldNotesArchievedModal
          show={state.showHoldNotesModal}
          hide={handleHoldNotesModals}
          holdNotesData={state.holdNotesData}
        />
        <HoldNoteCreateModal
          show={state.showHoldNoteCreateModal}
          hide={handleHoldNotesModals}
          // customers={this.state.customers}
          feedRestrictionsData={feedRestrictionsData}
          jewelryRestrictionData={jewelryRestrictionData}
          employeeData={employeeData}
          holdNotesData={state.holdNotesData}
          res={state.holdNoteItem}
          editHoldNote={state.showHoldNotesEditModal}
          handleItemHoldNoteUpdate={handleItemHoldNoteUpdate}
        />
      </Modal.Body>
    </Modal>
  );
}
