import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
// import "../../../styles/original.scss";
import CoverEdit from "./CoverEdit";
import { useDispatch } from "react-redux";
import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseCoverApp,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { toggleLoader } from "../../actions";
import { covers } from "./Cover";

export default function CoverIndex(props) {
  const [state, setState] = useState({
    showCoverEdit: false,
    coverDetails: "",
    covers: [],
  });
  const Dispatch = useDispatch();

  const handleCoverEdit = (value, cover) => {
    console.log({ value, cover });
    setState({
      ...state,
      showCoverEdit: value,
      coverDetails: cover ? cover : "",
    });
  };

  const handleCoverRefresh = (value) => {
    let updatedCovers =
      state.covers.filter((cover) => cover.id === value.id).length !== 0
        ? state.covers.map((cover) => {
            if (cover.id === value.id) {
              return value;
            } else {
              return cover;
            }
          })
        : [];
    console.log({ updatedCovers });
    if (updatedCovers.length !== 0) {
      setState({ ...state, covers: updatedCovers });
      return;
    }
    setState({ ...state, covers: [...state.covers, value] });
  };

  const handleCoverDelete = async (id) => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .delete({
        id: id,
      })
      .then(function (res) {
        // console.log("successfully deleted: ", res);
        return res;
      })
      .catch(function (err) {
        console.log("deletion error: ", err);
        toast.error(`Error in deleting template`, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        });
        return err;
      });
    if (response && response.result && response.result === "deleted") {
      console.log("Cover deleted.");
      let deletedCoverId = response._id;
      setState({
        ...state,
        covers: state.covers.filter((cover) => cover.id !== deletedCoverId),
      });
      Dispatch(
        toggleLoader({
          isLoading: false,
        })
      );
      toast.success(`Cover Deleted`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    } else {
      toast.error(`Error in deleting cover`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const fetchCovers = async () => {
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });

    let response = await appbaseRef
      .search({
        body: {
          query: {
            match_all: {},
          },
        },
      })
      .then(function (res) {
        // console.log("Templates results: ", res);
        return res;
      })
      .catch(function (err) {
        console.log("Covers error: ", err);
        return err;
      });

    if (response && response.hits.hits.length !== 0) {
      console.log("Covers found successfully");
      setState({
        ...state,
        covers: response.hits.hits.map((data) => {
          return { id: data._id, ...data._source };
        }),
      });
    } else {
      console.log("covers not found");
    }
  };

  useEffect(() => {
    fetchCovers();
  }, []);
  // console.log({ state });
  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleCoverModal(false)}
        className="email-template-modal"
      >
        <Modal.Header closeButton>Covers</Modal.Header>
        <Modal.Body>
          <div className="template_list">
            {/* <h5>Templates :</h5> */}

            {state.covers &&
              state.covers
                .sort((a, b) => {
                  let fa = a.Category.toLowerCase(),
                    fb = b.Category.toLowerCase();

                  if (fa < fb) {
                    return -1;
                  }
                  if (fa > fb) {
                    return 1;
                  }
                  return 0;
                })
                .map((cover) => (
                  <div className="template_container">
                    <label>{cover.Name} </label>
                    <label>{cover.Category} </label>
                    <button
                      onClick={() => {
                        handleCoverEdit(true, cover);
                      }}
                    >
                      Edit
                    </button>
                    <button onClick={() => handleCoverDelete(cover.id)}>
                      Delete
                    </button>
                  </div>
                ))}

            <button
              onClick={() => {
                handleCoverEdit(true, "");
              }}
            >
              New
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <CoverEdit
        show={state.showCoverEdit}
        coverDetails={state.coverDetails}
        handleCoverEdit={handleCoverEdit}
        handleCoverRefresh={handleCoverRefresh}
      />
    </>
  );
}
