import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (!value) {
      this.props?.keywordSearchChanged && this.props?.keywordSearchChanged("NOT_ACTIVE");
      return;
    }
    this.props?.keywordSearchChanged && this.props?.keywordSearchChanged("ACTIVE");
  }

  render() {
    return (
      <DataSearch
        className="form_field"
        componentId="Report"
        title="Report #"
        placeholder="Search keywords..."
        dataField={this.props.data}
        autosuggest={false}
        iconPosition="right"
        queryFormat="and"
        onValueChange={(value)=>{
          this.handleChange(value)
        }}
        // onValueSelected={(value) => {
        //   // if (value) {
        //   this.props.handleShowResults(true);
        //   // }
        // }}
      />
    );
  }
}

export default Report;
