import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCustomerApp,
  AppbaseCredentials,
  AppbaseEmployeeApp,
} from "../../utils/constants";

// export const feedRestrictionsData="";
// export const jewelryHoldRectrictionData="";
export const fetchApiData = async (index, type) => {
  const appbaseRef = Appbase({
    app: index,
    url: AppbaseAppUrl,
    credentials: AppbaseCredentials,
  });

  let response = await appbaseRef
    .search({
      body: {
        ...(index === AppbaseEmployeeApp
          ? {
              query: {
                match_all: {},
              },
              size: 10000,
            }
          : {}),
        ...(index === AppbaseCustomerApp
          ? {
              query: {
                match_phrase: {
                  Status: {
                    query: "Active",
                  },
                },
              },
              size: 10000,
            }
          : {}),
        ...(type === "feed"
          ? {
              query: {
                match_phrase: {
                  AttributeID: {
                    query: "KWFEEDS",
                  },
                },
              },
              sort: { SortOrder: { order: "asc" } },
              size: 10000,
              _source: ["Description", "ValueID"],
            }
          : {}),
        ...(type === "jewelry"
          ? {
              query: {
                match_phrase: {
                  AttributeID: {
                    query: "KWHLRSTRCT",
                  },
                },
              },
              sort: { SortOrder: { order: "asc" } },
              size: 10000,
              _source: ["Description", "ValueID"],
            }
          : {}),
      },
    })
    .then(function (res) {
      // console.log("customers results: ", res);
      return res;
    })
    .catch(function (err) {
      console.log("hold notes api data error: ", err);
      // return err;
    });
  if (
    response &&
    response.hits &&
    response.hits.hits &&
    response.hits.hits.length !== 0
  ) {
    return response.hits.hits;
  }
};

export const getHoldNotesData = async (id, app) => {
  const appbaseRef = Appbase({
    app: app,
    url: AppbaseAppUrl,
    credentials: AppbaseCredentials,
  });

  let response = await appbaseRef
    .search({
      body: {
        ...(id !== "fetch_All"
          ? {
              query: {
                match_phrase: {
                  serialID: {
                    query: id,
                  },
                },
              },
            }
          : {}),
        ...(id === "fetch_All"
          ? {
              query: {
                match_all: {},
              },
            }
          : {}),
        size: 10000,
      },
    })
    .then(function (res) {
      // console.log("hold notes results: ", res);
      return res;
    })
    .catch(function (err) {
      console.log("hold notes data error: ", err);
      // return err;
    });
  if (
    response &&
    response.hits &&
    response.hits.hits &&
    response.hits.hits.length !== 0
  ) {
    return response.hits.hits;
  }
};
// export const employeeData="";
