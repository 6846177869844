const jewelrySerialIncludeFields = {
  GeneralData: {
    SerialNumber: {
      label: "Serial Number",
      checkbox: true,
      advanced: false,
      // role: "advanced",
    },
    StyleNumber: {
      label: "Style Number",
      checkbox: true,
      advanced: false,
    },
    ItemType: {
      label: "Item Type",
      checkbox: true,
      advanced: false,
    },
    ItemSubtype: {
      label: "Item Sub Type",
      checkbox: false,
      advanced: false,
    },
    Collection: {
      label: "Collection",
      checkbox: true,
      advanced: false,
    },
    SubCollection: {
      label: "Sub Collection",
      checkbox: false,
      advanced: false,
    },
    Description: {
      label: "Description",
      checkbox: true,
      advanced: false,
    },
    Metal: {
      label: "Metal",
      checkbox: true,
      advanced: false,
    },
    Brand: {
      label: "Brand",
      checkbox: true,
      advanced: false,
    },
    RetailPrice: {
      label: "Retail Price",
      // checkbox: false,
      // advanced: false,
    },
    WholesalePrice: {
      label: "Wholesale Price",
      // checkbox: false,
      // advanced: false,
    },

    EditorialVideo: {
      label: "Item Reference Videos",
      checkbox: false,
      advanced: true,
    },
    WebImage1: {
      label: "WebImage1",
      checkbox: false,
      advanced: true,
    },
    WebImage2: {
      label: "WebImage2",
      checkbox: false,
      advanced: true,
    },
    WebImage3: {
      label: "WebImage3",
      checkbox: false,
      advanced: true,
    },
    WebImage4: {
      label: "WebImage4",
      checkbox: false,
      advanced: true,
    },
    WebImage5: {
      label: "WebImage5",
      checkbox: false,
      advanced: true,
    },
    LargeImageName: {
      label: "LargeImageName",
      checkbox: false,
      advanced: true,
    },
    Vendor: {
      label: "Vendor",
      checkbox: false,
      advanced: true,
    },
    VendorID: {
      label: "VendorID",
      checkbox: false,
      advanced: true,
    },
    VendorRefNbr: {
      label: "VendorRefNbr",
      checkbox: false,
      advanced: true,
    },
  },
  Dimensions: {
    BangleSize: {
      label: "Bangle Size",
      checkbox: false,
      advanced: false,
    },
    CaseLength: {
      label: "Case Length",
      checkbox: false,
      advanced: false,
    },
    CaseWidth: {
      label: "Case Width",
      checkbox: false,
      advanced: true,
    },
    Diameter: {
      label: "Diameter",
      checkbox: false,
      advanced: false,
    },
    ElementLength: {
      label: "Element Length",
      checkbox: false,
      advanced: true,
    },
  },
  Status: {
    Warehouse: {
      label: "Warehouse",
      checkbox: false,
      advanced: false,
    },
    StatusCustomer: {
      label: "Status Customer",
      checkbox: false,
      advanced: false,
    },
    StatusCustomerID: {
      label: "Status CustomerID",
      checkbox: false,
      advanced: true,
    },
    StatusDate: {
      label: "Status Date",
      checkbox: false,
      advanced: false,
    },
    StatusRefNbr: {
      label: "Status RefNbr",
      checkbox: false,
      advanced: true,
    },
    StatusAmount: {
      label: "Status Amount",
      checkbox: false,
      advanced: false,
    },
  },
};

const jewelrySerialIncludeFieldsAll = [
  "AdjustmentNbr",
  "BangleSize",
  "Brand",
  "CaseLength",
  "CaseWidth",
  "CenterCaratWeight",
  "CenterClarity",
  "CenterColor",
  "CenterCut",
  "CenterEnhancement",
  "CenterLabReportNbr",
  "CenterOrigin",
  "CenterShape",
  "CenterStoneNbr",
  "CircaDate",
  "Clarity",
  "ClosureType",
  "Collection",
  "Color",
  "ColorCarats",
  "ColorComment",
  "ConsignmentCost",
  "ConsignmentItem",
  "CountryOfOrigin",
  "Description",
  "Diameter",
  "DiamondCarats",
  "DiamondDetails",
  "DimensionTaken",
  "DimensionTakenDate",
  "DropLength",
  "EditorialVideo",
  "ElementLength",
  "ElementWidth",
  "EngraveLimit",
  "Feeds",
  "FinishedDWT",
  "FlushBand",
  "GemstoneType",
  "Height",
  "HoldBy",
  "HoldCustomerName",
  "HoldDate",
  "HoldNoteID",
  "HoldNoteNbr",
  "HoldText",
  "IconImageFileID",
  "IconImageName",
  "InStockCost",
  "InStockCostCode",
  "InStockDate",
  "InventoryCategory",
  "InventoryID",
  "Invid",
  "IsAshoka",
  "IsCom",
  "IsFLRound",
  "IsKWCushion",
  "IsKwiat",
  "IsMounted",
  "IsPhantom",
  "IsRtv",
  "IsSemimount",
  "IsSold",
  "IsTiara",
  "IsVirtual",
  "ItemClass",
  "ItemStatus",
  "ItemSubtype",
  "ItemType",
  "JewDataId",
  "LargeImageFileID",
  "LargeImageName",
  "Length",
  "LongDescription",
  "Maker",
  "MemoNbr",
  "MemoOutCustomer",
  "MemoOutCustomerID",
  "Metal",
  "MetalNote",
  "Milgrain",
  "MountedInventoryDBID",
  "MountedInventoryID",
  "MountingWholesale",
  "OwnCost",
  "PartwayEternity",
  "Period",
  "PricingDate",
  "PricingMode",
  "ProfitMargin",
  "Provenance",
  "PurchaseRefNumber",
  "RTVDate",
  "ReleaseDate",
  "RetailMarkup",
  "RetailPrice",
  "RetailPriceCode",
  "RingSize",
  "SearchType",
  "SemiMount",
  "SemimountType",
  "SerialLastModifiedDate",
  "SerialNumber",
  "SerialStatus",
  "SerialVideoLink",
  "SettingType",
  "ShortDescription",
  "SideStoneCaratWeight",
  "SizingNotes",
  "SoldCustomer",
  "SoldCustomerID",
  "SoldDate",
  "SoldDiscount",
  "SoldDiscountCode",
  "SoldMargin",
  "SoldMarginCode",
  "SoldPrice",
  "SoldPriceCode",
  "SoldRefNumber",
  "StatusAmount",
  "StatusCustomer",
  "StatusCustomerID",
  "StatusDate",
  "StatusRefNbr",
  "StoneOrientation",
  "StyleNumber",
  "StyleStatus",
  "StyleVideoLink",
  "SubCollection",
  "TotalMarkup",
  "UsrMountedStockNbr",
  "Vendor",
  "VendorID",
  "VendorRefNbr",
  "WRShape",
  "Warehouse",
  "WebImage1",
  "WebImage2",
  "WebImage3",
  "WebImage4",
  "WebImage5",
  "WholesaleMarkup",
  "WholesalePrice",
  "WholesalePriceCode",
  "WidthOD",
  "_click_id",
  "_id",
  "_index",
  "_score",
  "_type",
  "dimensionTakenBy",
  "highlight",
  "index",
  "operationType",
  "sort",
  "transformType",
];

const jewelryStyleIncludeFields = [
  "AdjustmentNbr",
  "ChildPricingMethod",
  "ConsignmentCost",
  "ConsignmentItem",
  "CreatedByID",
  "CreatedByScreenID",
  "CreatedDateTime",
  "EditorialVideo",
  "HoldCustomerName",
  "HoldDate",
  "HoldText",
  "IconImageFileID",
  "IconImageName",
  "InStockCost",
  "InStockCostCode",
  "InStockDate",
  "InventoryCategory",
  "InventoryDBID",
  "InventoryID",
  "Invid",
  "IsCom",
  "IsMounted",
  "IsPhantom",
  "IsRtv",
  "IsVirtual",
  "JewLastModifiedDateTime",
  "kwesjewelryserialsearch_lastModifiedDateTime",
  "LargeImageFileID",
  "LargeImageName",
  "LastModifiedByID",
  "LastModifiedByScreenID",
  "LastModifiedDateTime",
  "Location",
  "MemoOutCustomer",
  "MemoOutCustomerID",
  "MetalNote",
  "MountingWholesale",
  "OwnCost",
  "PricingDate",
  "PricingMode",
  "ProfitMargin",
  "PurchaseRefNumber",
  "QtyInTransit",
  "QtyOnHand",
  "Refnoteid",
  "ReleaseDate",
  "RetailMarkup",
  "RetailPriceCode",
  "Rtvdate",
  "SearchType",
  "Siteid",
  "SoldCustomer",
  "SoldCustomerID",
  "SoldDate",
  "SoldDiscount",
  "SoldDiscountCode",
  "SoldMargin",
  "SoldMarginCode",
  "SoldPrice",
  "SoldPriceCode",
  "SoldRefNumber",
  "sort",
  "StatusAmount",
  "StatusCustomer",
  "StatusCustomerID",
  "StatusDate",
  "StatusRefNbr",
  "StatusRefType",
  "StyleStatus",
  "StyleStatusDate",
  "StyleVideoLink",
  "TotalMarkup",
  "UsrMountedStockNbr",
  "Vendor",
  "VendorID",
  "VendorRefNbr",
  "Warehouse",
  "WholesaleMarkup",
  "WholeSaleMarkup",
  "WholesalePriceCode",
];
const diamondDataExport = {
  // "AdjustmentNbr",
  // "Brand",
  // "ConsignmentCost",
  // "ConsignmentItem",
  Depth: {
    label: "Depth",
  },
  DepthP: {
    label: "Depth %",
  },
  // "Description",
  DiaVideoLink: {
    label: "DiaVideoLink",
  },
  DiamondCaratWeight: {
    label: "Carats",
  },
  DiamondClarityRange: {
    label: "Clarity",
  },
  DiamondColorRange: {
    label: "Color",
  },
  DiamondCut: {
    label: "Cut",
  },
  // "DiamondSizeCategory",
  // "DiamondTiaraInscription",
  // "FancyColor",
  FancyColorGIA: {
    label: "FancyColorGIA",
  },
  // "FancyColorIntensity",
  // "GIANumber",
  // "GIAReportDate",
  GemstoneType: {
    label: "Gem Type",
  },
  // "Giapdfurl",
  Giashape: {
    label: "GIA Shape",
  },
  GradingLab: {
    label: "Lab Report",
  },
  // "HoldBy",
  // "HoldCustomerName",
  // "HoldDate",
  // "HoldNoteID",
  // "HoldNoteNbr",
  // "HoldText",
  // "IconImageFileID",
  // "IconImageName",
  // InStockCost: {
  //   label: "Price",
  // },
  // "InStockCostCode",
  // "InStockCostPerCarat",
  // "InStockCostPerCaratCode",
  // "InStockDate",
  // "InStockRelToList",
  // "Inscription",
  // "InternalUseComments",
  // "InventoryCategory",
  // "InventoryID",
  // "Invid",
  // "IsFLCushion",
  // "IsFLRound",
  // "IsKWCushion",
  // "IsMounted",
  // "IsPhantom",
  // "IsRtv",
  // "IsSold",
  // "IsTiara",
  // "IsVirtual",
  // "ItemClass",
  // "ItemStatus",
  // "KeyDatesID",
  LabReportNbr: {
    label: "LabReportNbr",
  },
  // "LargeImageFileID",
  // "LargeImageName",
  // "LastModifiedOn",
  Length: {
    label: "Length",
  },
  // "LongDesc",
  // "LotSerialNbr",
  // "MemoOutCustomer",
  // "MemoOutCustomerID",
  // "MountedInventoryDBID",
  // "MountedInventoryID",
  // "MountedItemType",
  // "MountedStockNumber",
  // "OwnCost",
  Polish: {
    label: "Polish",
  },
  // "PricingDate",
  // "PricingMode",
  // "ProfitMargin",
  // "PurchaseRefNumber",
  // "RTVInitial",
  // "RapListPerCarat",
  // "RapListPerCaratCode",
  // "ReleaseDate",
  // "RetailMarkup",
  // "RetailPriceCode",
  // "RetailPricePerCarat",
  // "RetailRelToList",
  // "Rtvdate",
  SerialNumber: {
    label: "Inventory #",
  },
  // "SerialStatus",
  // "SerialVideoLink",
  Shape: {
    label: "Shape",
  },
  // "ShortDesc",
  // "Siteid",
  // "SoldCustomer",
  // "SoldCustomerID",
  // "SoldDate",
  // "SoldDiscount",
  // "SoldDiscountCode",
  // "SoldMargin",
  // "SoldMarginCode",
  // "SoldPrice",
  // "SoldPriceCode",
  // "SoldRefNumber",
  // "StatusAmount",
  // "StatusCustomer",
  // "StatusCustomerID",
  // "StatusDate",
  // "StatusRefNbr",
  // "StoneCulet",
  StoneFluoresence: {
    label: "StoneFluoresence",
  },
  StoneFluoresenceColor: {
    label: "StoneFluoresenceColor",
  },
  // "StoneGirdle",
  StoneRatio: {
    label: "Ratio",
  },
  // "StyleNumber",
  // "StyleVideoLink",
  Symmetry: {
    label: "Symmetry",
  },
  TableP: {
    label: "Table %",
  },
  // "Vendor",
  // "VendorID",
  // "VendorRefNbr",
  // "Warehouse",
  // "WholesaleMarkup",
  // "WholesalePerCarat",
  // "WholesalePerCaratCode",
  RetailPrice: {
    label: "Retail Price",
  },
  WholesalePrice: {
    label: "Wholesale Price",
  },
  // "WholesalePriceCode",
  // "WholesalePricePerCarat",
  // "WholesaleRelToList",
  Width: {
    label: "Width",
  },
  // "highlight",
  // "operationType",
  // "transformType",
};

const diamondDataHeaderExport = [
  { label: "Shape", key: "Shape" },
  { label: "Gem Type", key: "Gem Type" },
  { label: "Inventory #", key: "Inventory #" },
  { label: "GIA Shape", key: "GIA Shape" },
  { label: "Carats", key: "Carats" },
  { label: "Color", key: "Color" },
  { label: "Clarity", key: "Clarity" },
  { label: "Cut", key: "Cut" },
  { label: "Lab Report", key: "Lab Report" },
  { label: "Polish", key: "Polish" },
  { label: "Symmetry", key: "Symmetry" },
  { label: "Flouresence", key: "Fluoresence" },
  { label: "Dimensions", key: "Dimensions" },
  { label: "Ratio", key: "Ratio" },
  { label: "Depth %", key: "Depth %" },
  { label: "Table %", key: "Table %" },
  { label: "Video", key: "Video" },
  { label: "Retail", key: "Retail Price" },
  { label: "Wholesale", key: "Wholesale Price" },
];

const gemstoneDataExport = [
  "AdjustmentNbr",
  "ConsignmentCost",
  "ConsignmentItem",
  "HoldBy",
  "HoldCustomerName",
  "HoldDate",
  "HoldText",
  "IconImageFileID",
  "IconImageName",
  "InStockCost",
  "InStockCostCode",
  "InStockCostPerCarat",
  "InStockCostPerCaratCode",
  "InStockDate",
  "InternalUseComments",
  "InventoryID",
  "Invid",
  "IsPhantom",
  "IsRtv",
  "IsSold",
  "IsVirtual",
  "kwesgemstoneserialsearch_lastModifiedDateTime",
  "LargeImageFileID",
  "LargeImageName",
  "MemoOutCustomer",
  "MemoOutCustomerID",
  "OwnCost",
  "PricingDate",
  "PricingMode",
  "ProfitMargin",
  "PurchaseRefNumber",
  "QtyInTransit",
  "QtyOnHand",
  "Refnoteid",
  "ReleaseDate",
  "RetailMarkup",
  "RetailPriceCode",
  "RetailPricePerCarat",
  "RetailRelToList",
  "Rtvdate",
  "Siteid",
  "SoldCustomer",
  "SoldCustomerID",
  "SoldDate",
  "SoldDiscount",
  "SoldDiscountCode",
  "SoldMargin",
  "SoldMarginCode",
  "SoldPrice",
  "SoldPriceCode",
  "SoldRefNumber",
  "sort",
  "StatusAmount",
  "StatusCustomer",
  "StatusCustomerID",
  "StatusDate",
  "StatusRefNbr",
  "StatusRefType",
  "Vendor",
  "VendorID",
  "VendorRefNbr",
  "Warehouse",
  "WholesaleMarkup",
  "WholesalePerCarat",
  "WholesalePerCaratCode",
  "WholesalePriceCode",
  "WholesalePricePerCaratt",
  "WholesaleRelToList",
];

export {
  jewelrySerialIncludeFields,
  jewelryStyleIncludeFields,
  diamondDataExport,
  diamondDataHeaderExport,
  gemstoneDataExport,
};
