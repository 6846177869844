import React, { Component } from "react";
import { ReactiveBase, ReactiveList } from "@appbaseio/reactivesearch";
import { DataSearch } from "@appbaseio/reactivesearch";
import './MTSsearch.css';
import { MTSApp, AppbaseAppUrl, AppbaseCredentials } from "../../utils/constants"
import Navigation from "../Navigation"

const Loader = () => {
  return (
    <div className="es-product-loader">
      <i
        className="fa fa-spinner fa-2x fa-spin"
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
      />
    </div>
  );
};

class MTSSearch extends Component {
  constructor(props) {
    super(props);
    this.onTabChange = this.onTabChange.bind(this);
    this.iframeRef = React.createRef();
    this.state = {
      isLoading: false,
      activeTabKey: "serial",
      title: "MTS",
      hideSearchBar: false,
      emailValue: ""
    };
    this.generateQuery = this.generateQuery.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
  }

  componentDidMount() {
    const data = JSON.parse(sessionStorage.getItem('reduxState'))
    this.setState({
      hideSearchBar: false,
      emailValue: data?.basketInputChange?.user?.Email || "xyz@kwiat.com"
    })
  }

  handleEmailChange(value) {
    if (false) {
      this.setState({
        emailValue: value
      });
    }
  }

  onTabChange(tabKey) {
    this.props.history.push(`/MTS-search/${tabKey}`);
    this.setState({
      isLoading: true,
      activeTabKey: tabKey
    });

    // Simulate ajax calls
    setTimeout(() => {
      this.setState({
        isLoading: false
      });
    }, 800);
  }

  generateQuery(params) {
    if (params && Object.entries(params).length > 0) {
      return Object.entries(params).reduce((acc, [key, val]) => {
        if (val == null || val === '' || (Array.isArray(val) && !val.length)) return acc;
        return acc + `${acc ? '&' : '?'}${key}=${encodeURIComponent(typeof val === 'string' ? val : JSON.stringify(val))}`;
      }, '');
    } else {
      return '';
    }
  }

  render() {
    const { activeTabKey, isLoading, title } = this.state;
    return (
      <>
        <div className="navbar_container">
          <Navigation handleShowResults={this.handleShowResults} />
        </div>
        <ReactiveBase
          className="es__base-component"
          app={MTSApp}
          url={AppbaseAppUrl}
          credentials={AppbaseCredentials}
        >
          {
            <DataSearch
              className="form-field2"
              placeholder={"Enter your email..."}
              dataField={"email"}
              autosuggest={true}
              queryFormat="and"
              componentId="EmailSearch"
              iconPosition={"left"}
              onValueSelected={(e) => {
                this.setState({
                  hideSearchBar: true
                })
              }}
              // defaultValue="cory@kwiat.com"
              value={this.state.emailValue}
              onChange={this.handleEmailChange}
            />
          }
          <ReactiveList
            dataField="email"
            // className={!this.state.hideSearchBar ? "visiblityHidden": ""}
            componentId="results"
            react={{
              and: ["EmailSearch"]
            }}
            innerClass={{
              resultsInfo: "results-info"
            }}
            renderNoResults={()=>""}
            render={
              ({ data, loading }) => {
                return (<div>
                  {loading && <Loader/>}
                  {!loading && data.length === 0 && <div className="no-result-found" >Contact your administrator to get setup for the MTS App</div>}
                  {data.length === 1 && data?.map((item, ind) => {
                    const baseUrl = 'https://minetoshine.io/user-session';
                    const mineToShine = baseUrl +
                      this.generateQuery(
                        {
                          acu_customer_id: item?.acu_customer_id,
                          associate_firstname: item?.first_name,
                          associate_lastname: item?.last_name,
                          associate_email: item?.email,
                          associate_userid: item?.user_id,
                          store_name: "Bhushan",
                          customer_type: item?.type,
                          client_contact_capture: 0,
                          'mts-key': 1,
                        }
                      )

                    return (
                      <div key={ind} className="body-con" style={{
                        // width: "100%",
                        // boxSizing: "border-box"
                      }}>
                        {
                          <iframe
                            src={mineToShine}
                            style={{ border: "none" }}
                            height="500px"
                            width="100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            // allowFullScreen="true"
                          />
                        }
                      </div>
                    )
                  })}
                </div>)
              }
            }

          />
        </ReactiveBase>
      </>
    )
  }
}

export default MTSSearch;

