import React, { Component } from "react";
import { MultiList } from "@appbaseio/reactivesearch";

class IsSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: ["0"]
    };
    this.handleValueChange = this.handleValueChange.bind(this)
  }

  componentDidMount() {
    if (this.props?.keywordSearchSignal === "ACTIVE") {
      this.setState({ checkedValues: ["0", "1"] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.keywordSearchSignal !== this.props?.keywordSearchSignal) {
      if (this.props?.keywordSearchSignal === "ACTIVE") {
        this.setState({ checkedValues: ["0", "1"] });
      } else {
        this.setState({ checkedValues: ["0"] });
      }
    }
  }

  handleValueChange = (value) => {
    this.setState({ checkedValues: value });
  };

  render() {
    return (
      <MultiList
        className="form_field"
        componentId="IncludeSold"
        dataField="IsSold.keyword"
        defaultValue={["0"]}
        queryFormat="or"
        showFilter={true}
        showSearch={false}
        sortBy="asc"
        render={({ data, value, handleChange }) => {
          // console.log("data : ", data);
          let {checkedValues} = this.state
          const checkedFlag = checkedValues.includes("1")
          if (data.length === 1) {
            return (
              <ul>
                <li />
                <li>
                  <input
                    type="checkbox"
                    value={1}
                    onChange={(e)=>{
                      handleChange(e);
                      this.handleValueChange(e.target.checked ? ["0", "1"] : ["0"]);
                    }}
                    checked={checkedFlag}
                  />
                  <span className="toggle--label"> + Sold</span>
                </li>
              </ul>
            );
          } else {
            return (
              <ul>
                {data.map((item) => {
                  return (
                  <li key={item.key}>
                    <input
                      type="checkbox"
                      value={item.key}
                      onChange={(e)=>{
                        handleChange(e)
                        const newValue = e.target.checked
                            ? [...checkedValues, item.key]
                            : checkedValues.filter((val) => val !== item.key);
                        this.handleValueChange(newValue);
                      }}
                      checked={checkedFlag}
                    />
                    <span className="toggle--label"> + Sold</span>
                  </li>
                )})}
              </ul>
            );
          }
        }}
        beforeValueChange={function (value) {
          return new Promise((resolve, reject) => {
            if (!value) {
              reject();
            }
            resolve();
          });
        }}
        // onValueChange={(value) => {
        //   // if (value) {
        //   this.props.handleShowResults(true);
        //   // }
        // }}
      />
    );
  }
}

export default IsSold;
