export const regex = (string) => {
  // /('(?=(,\s*')))|('(?=:))|((?<=([:,]\s*))')|((?<={)')|('(?=}))/g;
  let script = /('(?=(,\s*')))|('(?=:))|('(?=}))/g;
  string = string.replace(script, '"');
  string = string.replace(/([:,]\s*)?'/g, function ($0, $1) {
    return $1 ? $1 + '"' : $0;
  });
  string = string.replace(/({)?'/g, function ($0, $1) {
    return $1 ? $1 + '"' : $0;
  });
  string = string.replace(/\\|\//g, function ($0, $1) {
    return $1 ? $1 + "" : $0;
  });
  return string;
};
