import React, { Component } from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

class ReportKeyword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  render() {
    return (
      <DataSearch
        className="form_field"
        componentId="SearchKeywo"
        title={"Report"}
        debounce={250}
        placeholder="Start Search..."
        dataField={["ComponentReportNumbers"]}
        autosuggest={false}
        queryFormat="and"
        searchOperators={true}
        iconPosition="right"
      />
    );
  }
}

export default ReportKeyword;
