import React, { Component } from "react";
import Header from "./Header";
import JewelryMain from "./Jewelry/JewelryMain";
import StudsMain from "./Studs/StudsMain";
import { Switch, Route, Redirect } from "react-router-dom";
import SingleItem from "./SingleItemView/SingleItem";
import DiamondMain from "./Diamond/DiamondMain";
import MTSDiamond from "./MTS-Diamond/MTSDiamondMain"
import GemstoneMain from "./Gemstone/GemstoneMain";
import MTSSearch from "./MTS-search/index"
import ScrollToTop from "react-scroll-to-top";
import Arrow from "../assets/icons/arrow-151-24.png";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import IframeModal from "./OtherComponents/IframeModal";
import {
  setGroups,
  setBasketFormInput,
  toggleBasket,
  setEmployeeData,
  setFeedsHoldData,
  setJewelryHoldData,
} from "./actions";
import { bindActionCreators } from "redux";
import axios from "axios";
// import { cloudflareAccessKey, cloudflareAccessUrl } from "../utils/constants";
import Cookies from "js-cookie";
import Appbase from "appbase-js";
import {
  AppbaseEmployeeApp,
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseAttributesApp,
} from "../utils/constants";
import { fetchApiData } from "./OtherComponents/holdNotesApiData";

const mapStateToProps = (state) => {
  return {
    isLoading: state.loaderActions.isLoading,
    groups: state.SetGroups.groups,
  };
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackButton: false,
    };
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleGetGroups = this.handleGetGroups.bind(this);
  }

  async componentDidMount() {
    this.props.toggleBasket({ show: false });
    await this.handleGetGroups();
    let groups = this.props.groups.groups;
    // console.log("groups :", groups);
    let accessGroup = groups.filter((group) =>
      [
        "227b908c-faec-4169-a1f0-a45e217725e5",
        "1dd4718d-e6a6-4d12-b536-d9f0d6025a51",
        "6e9595ab-6424-43e7-a367-91248b278798",
      ].includes(group.id)
    );
    // console.log({ accessGroup });
    if (accessGroup.length !== 0) {
      this.props.setBasketFormInput({
        access: "advanced",
        
      });
      this.props.setBasketFormInput({ holdNotesAccess: true,dwebbNoDefault: true });
    }
    groups.map((group) => {
      if (group.id === "5036fc5b-9077-4513-8296-47032fc363a9") {
        this.props.setBasketFormInput({ holdNotesAccess: false,dwebbDefault: true });
      }else{
        this.props.setBasketFormInput({ holdNotesAccess: true });
      }
    });
    await this.getUserEmail();
    let empResponse = await fetchApiData(AppbaseEmployeeApp);
    // console.log({ empResponse });
    if (empResponse) {
      // this.setState({ employeeData: empResponse });
      this.props.setEmployeeData(empResponse);
    }
    // let custResponse = await fetchApiData(AppbaseCustomerApp);
    // if (custResponse) {
    //   this.setState({ customers: custResponse });
    // }
    let feedResponse = await fetchApiData(AppbaseAttributesApp, "feed");
    // console.log({ feedResponse });
    if (feedResponse) {
      // this.setState({ feedRestrictionsData: feedResponse });
      this.props.setFeedsHoldData(feedResponse);
    }
    let jewelryRespone = await fetchApiData(AppbaseAttributesApp, "jewelry");
    // console.log({ jewelryRespone });
    if (jewelryRespone) {
      // this.setState({ jewelryRestrictionData: jewelryRespone });
      this.props.setJewelryHoldData(jewelryRespone);
    }
  }

  async handleGetGroups() {
    let res = await axios.get(`/api/index`, { credentials: "include" });

    // console.log("res: ", res);
    if (res.data.response.status === 200) {
      let groupData = res.data.data;
      this.props.setGroups({ groups: groupData });
    } else {
      console.log("Error in fetching");
    }
  }

  async getUserEmail() {
    let userEmail;
    if (this.props.groups.email) {
      userEmail = this.props.groups.email;
    } else if (window.parent.document.getElementById("btnUserMenu")) {
      // userEmail = window.parent.document
      //   .getElementById("btnUserMenu")
      //   .querySelector(".user-name").textContent;
      userEmail = window.parent.document
        .getElementById("userMenu")
        .querySelector(
          "li.menu-form > div.inline > span:nth-child(3)"
        ).textContent;
    } else {
      userEmail = null;
      // userEmail = "sahil@kwiat.com";
      // userEmail = "megan@kwiat.com";
      // userEmail = "nagaraj@kwiat.com";
      // userEmail = "josh@kwiat.com";
      // userEmail = "cory@kwiat.com";
    }
    // console.log("userEmail: ", userEmail);

    const appbaseRef = Appbase({
      app: AppbaseEmployeeApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });
    if (userEmail) {
      await appbaseRef
        .search({
          body: {
            query: {
              match_phrase: {
                Email: {
                  query: userEmail,
                },
              },
            },
            size: 10000,
            // stored_fields: ["Email"], // for getting only ids
            _source: [
              "FirstName",
              "LastName",
              "Email",
              "BasketEmail",
              "EmployeeLogin",
              "Title",
              "Phone1",
              "AddressLine1",
              "AddressLine2",
              "City",
              "State",
              "PostalCode",
              "BasketBccEmail",
              "CRMBccEmail",
            ],
          },
        })
        .then((response) => {
          console.log("User Email Found Successfully");
          // console.log("response : ", response.hits.hits[0]._source);
          this.props.setBasketFormInput({
            user: response.hits.hits[0]._source,
          });
          // console.log("Basket form data: ", this.props.basketInputObj);
          // result = { statusCode: 200, data: response };
        })
        .catch((error) => {
          console.log("User Email Error");

          // console.log("error : ", error);
          // return;
          // result = { statusCode: 500, data: error };
        });
      // let payload = {
      //   data: {
      //     defaults: {
      //       baseURL: basketBaseUrl,
      //       token: token,
      //     },
      //     inputs: {
      //       EmployeeLogin: {
      //         value: this.props.basketInputObj.user.EmployeeLogin,
      //       },
      //     },
      //   },
      // };
      // // console.log("payload: ", payload);
      // await axios
      //   .post(appbaseBasketUrl + "user-signature", payload, {
      //     headers: {
      //       "x-api-key": basketApikey,
      //     },
      //   })
      //   .then((res) => {
      //     // console.log("resp : ", res);
      //     if (
      //       res.data.statusCode === 200 &&
      //       res.data.body.KWMailSignature.value
      //     ) {
      //       console.log("User signature found.");
      //       this.props.setBasketFormInput({
      //         user: {
      //           ...this.props.basketInputObj.user,
      //           Signature: res.data.body.KWMailSignature.value,
      //         },
      //       });
      //     } else {
      //       console.log("response is empty");
      //     }
      //     // console.log("Basket form data: ", this.props.basketInputObj);
      //     // return JSON.parse(res.data.body);
      //     // return res;
      //   })
      //   .catch((err) => {
      //     // console.log("error : ", err);
      //     console.log("User Signature error.", err);
      //     // return;
      //   });
    } else {
      console.log("No user");
    }
    // if (window.parent.document.getElementById("btnUserMenu")) {
    //   let userDetails = window.parent.document
    //     .getElementById("userMenu")
    //     .querySelector("li.menu-form > div.inline > span:nth-child(3)").textContent;
    //   console.log("userDetails: ", userDetails);
    // }
  }

  handleBackButton(value) {
    this.setState({ showBackButton: value });
  }

  render() {
    return (
      <LoadingOverlay active={this.props.isLoading} spinner text="Loading...">
        <div className="main_container">
          <ScrollToTop component={<img src={Arrow}></img>} smooth />
          {/* <Header
            showBackButton={this.state.showBackButton}
            handleBackButton={this.handleBackButton}
          /> */}
          <Switch>
            <Route
              path="/"
              exact
              render={() => {
                return <Redirect to="/JewelrySerial" />;
              }}
            />
            <Route
              exact
              path="/JewelrySerial"
              render={(props) => (
                <JewelryMain
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            <Route
              exact
              path="/DiamondSerial"
              render={(props) => (
                <DiamondMain
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            <Route
              exact
              path="/GemstoneSerial"
              render={(props) => (
                <GemstoneMain
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            <Route
              exact
              path="/MTS-diamond-search"
              render={(props) => (
                <MTSDiamond
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            <Route
              exact
              path="/MTS-search"
              render={(props) => (
                <MTSSearch
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            <Route
              exact
              path="/Studs"
              render={(props) => (
                <StudsMain
                  {...props}
                  handleBackButton={this.handleBackButton}
                />
              )}
            />
            {/*} <Route
            path={[
              "/JewelrySerial/:id",
              "/DiamondSerial/:id",
              "/GemstoneSerial/:id",
            ]}
            render={(props) => <SingleItem {...props} />}
          /> */}
          </Switch>
          <IframeModal />
        </div>
      </LoadingOverlay>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setGroups,
      setBasketFormInput,
      toggleBasket,
      setEmployeeData,
      setFeedsHoldData,
      setJewelryHoldData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
