import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseTemplateApp,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { toggleLoader } from "../../actions";

export default function TemplateEdit(props) {
  let { show, handleTemplateEdit, templateDetails } = props;
  const [state, setState] = useState({
    id: "",
    templateName: "",
    templateCategory: "",
    templateSubject: "",
    templateBody: "",
    templateAttachment: "",
  });
  const Dispatch = useDispatch();

  // const updatedStateValue = useMemo(
  //   () => {
  //     return setTemplateDetails();
  //   },
  //   [templateDetails]
  // );

  const handleTemplateReset = () => {
    setState({
      id: "",
      templateName: "",
      templateCategory: "",
      templateSubject: "",
      templateBody: "",
      templateAttachment: "",
    });
  };

  const setTemplateDetails = () => {
    setState({
      ...state,
      id: templateDetails.id ? templateDetails.id : "",
      templateName: templateDetails.Name ? templateDetails.Name : "",
      templateCategory: templateDetails.Category
        ? templateDetails.Category
        : "",
      templateSubject: templateDetails.Subject ? templateDetails.Subject : "",
      templateAttachment: templateDetails.Attachment
        ? templateDetails.Attachment
        : "",
      templateBody: templateDetails.Body ? templateDetails.Body : "",
    });
  };

  const addTemplate = async () => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    const appbaseRef = Appbase({
      app: AppbaseTemplateApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });
    let currentdate = new Date();
    let dateTime =
      currentdate.getFullYear() +
      "" +
      (currentdate.getMonth() + 1) +
      "" +
      currentdate.getDate() +
      "" +
      currentdate.getHours() +
      "" +
      currentdate.getMinutes() +
      "" +
      currentdate.getSeconds();
    let payload = {
      DateCreated: dateTime,
      Category: state.templateCategory,
      Name: state.templateName,
      Subject: state.templateSubject,
      Attachment: state.templateAttachment,
      Body: state.templateBody,
    };

    let response = await appbaseRef
      .index({
        ...(![undefined, null, ""].includes(state.id) && { id: state.id }),
        body: payload,
      })
      .then(function (res) {
        // console.log("Template added successfully: ", res);
        return res;
      })
      .catch(function (err) {
        console.log("Template error: ", err);
        toast.error(`Error in adding template`, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        });
        return err;
      });
    if (
      response &&
      response.result &&
      ["created", "updated"].includes(response.result)
    ) {
      props.handleTemplateRefresh({
        id: response._id,
        Attachment: payload.Attachment,
        Body: payload.Body,
        Category: payload.Category,
        Name: payload.Name,
        Subject: payload.Subject,
      });
      Dispatch(
        toggleLoader({
          isLoading: false,
        })
      );
      toast.success(
        `${
          state.id
            ? `Template updated successfully`
            : `Template Added successfully`
        }`,
        {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        }
      );
      handleTemplateEdit(false, "");
    } else {
      toast.error(`Error in adding template`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    setTemplateDetails();
  }, [templateDetails]);

  return (
    <Modal
      show={show}
      className="template-edit-modal"
      onHide={() => handleTemplateEdit(false, "")}
    >
      <Modal.Header closeButton>Template Details</Modal.Header>
      <Modal.Body>
        <div className="template_edit">
          <div className="template_input">
            <label> Name:</label>
            <input
              type="text"
              placeholder="Template Name..."
              value={state.templateName}
              onChange={(e) => {
                setState({ ...state, templateName: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label> Category:</label>
            <input
              type="text"
              placeholder="Template Category..."
              value={state.templateCategory}
              onChange={(e) => {
                setState({ ...state, templateCategory: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Subject:</label>
            <input
              type="text"
              placeholder="Template Subject..."
              value={state.templateSubject}
              onChange={(e) => {
                setState({ ...state, templateSubject: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Attachment:</label>
            <input
              type="text"
              placeholder="Template Attachment..."
              value={state.templateAttachment}
              onChange={(e) => {
                setState({ ...state, templateAttachment: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Body:</label>
            <textarea
              type="text"
              placeholder="Body ..."
              value={state.templateBody}
              onChange={(e) => {
                setState({ ...state, templateBody: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <button onClick={() => addTemplate()}>Save</button>
            <button onClick={() => handleTemplateReset()}>Clear</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
