import React from "react";
import { Modal, Tabs, Tab, Table } from "react-bootstrap";
export default function AttachmentModal(props) {
  let { show, hide, handleSelectedFile } = props;
  return (
    <Modal
      show={show}
      size="lg"
      onHide={() => {
        hide(false);
      }}
      animation={false}
      // dialogClassName="email_modal"
      className="attachment_modal"
      // className="preview-details-modal"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      id="preview-modal"
    >
      {/* <LoadingOverlay active={isLoading} spinner text="Loading..."> */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select Attachment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="attachment_container">
          <div>
            The attachments are accepted with png, gif, svg, jpeg, pdf, csv,
            json, xml, xhtml, html, txt, zip, gzip, mp4 & mesh extensions.{" "}
          </div>
          {/* <label className="control-label">Select File To Upload :</label> */}
          <input
            type="file"
            onChange={handleSelectedFile}
            accept=".png,.gif,.svg,.jpeg,.pdf,.csv,.json,.xml,.xhtml,.html,.txt,.zip,.gzip,.mp4,.mesh"
          />
          {/* <button
              className="btn btn-default Retailer-ContactForm__footer__rightcol-btn"
              onClick={handleUpload}
            >
              Upload
            </button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}
