import React, { Component, useState } from "react";
import Card from "react-bootstrap/Card";
import currencyFormatter from "currency-formatter";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleSingleView, addToCart, removeFromCart, toggleIframeModal } from "../actions";
import SingleItem from "../SingleItemView/SingleItem";
import PreviewEmailModal from "../Basket/PDF/PreviewEmailModal";
import PDFModal from "../Basket/PDF/PDFModal";
import ChooseLayoutModal from "../Basket/ChooseLayoutModal";
import cx from "classnames";

export default function Results(props) {
  let {
    items,
    viewType,
    handleBackButton,
    isValueEmpty,
    isMultipleValueEmpty,
    handleStockPopUpModal,
  } = props;
  const [data, setData] = useState({
    currentRes: [],
    showPreviewModal: false,
    showPDFModal: false,
    // showChooseLayout: false,
    // layoutType: "",
    coverType: "NoCover",
    // goto: "",
    includeGIA: "No",
  });
  const { show, item } = useSelector((state) => state.singleViewModal);
  const [showChooseLayout, setShowChooseLayout] = useState(false);
  const [goto, setGoto] = useState("");
  const [layoutType, setLayoutType] = useState("");
  const basket = useSelector((state) => state.basket);
  const showWholesale = useSelector(
    (state) => state.basketInputChange.showWholesale
  );
  // console.log("items : ", items);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleImage = (item) => {
    if (
      (item.transformType === "JewelrySerial" || !item.transformType) &&
      item.WebImage1
    ) {
      //   console.log(item);
      let str = item.WebImage1.replace(".jpg", "-product@2x.jpg");
      let imageurl = "https://cdn.kwiat.com/source-images/web/product/" + str;
      return imageurl;
    } else if (
      (item.transformType === "JewelrySerial" || !item.transformType) &&
      item.InvWebImage1
    ) {
      //   console.log(item);
      let str = item.InvWebImage1.replace(".jpg", "-product@2x.jpg");
      let imageurl = "https://cdn.kwiat.com/source-images/web/product/" + str;
      return imageurl;
    } else if (
      item.transformType === "DiamondSerial" ||
      item.transformType === "GemstoneSerial" ||
      ((item.transformType === "JewelrySerial" || !item.transformType) &&
        item.Shape)
    ) {
      // console.log("Inside shape");
      let imageurl =
        "https://cdn.kwiat.com/apps/kwiat-elastic-search/dia-shapes/" +
        item.Shape +
        ".jpg";
      return imageurl;
    } else if (item.LargeImageName) {
      // console.log("Inside Large Image Name");
      let searchimage;
      searchimage = item.LargeImageName;
      let str = searchimage.split("\\");
      searchimage = str[str.length - 1];
      let imageurl = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      return imageurl;
    } else if (item.InvLargeImageName) {
      // console.log("Inside Large Image Name");
      let searchimage;
      searchimage = item.InvLargeImageName;
      let str = searchimage.split("\\");
      searchimage = str[str.length - 1];
      let imageurl = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      return imageurl;
    }
    else {
      let imageurl =
        "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
      return imageurl;
    }
  };

  const handleIncludeGIA = (value) => {
    setData({ ...data, includeGIA: value });
  };
  const handleEmail = async (res) => {
    console.log("Inside handle email");
    // if (
    //   this.props.basketInputObj.orderNbr &&
    //   this.props.basketInputObj.orderNbr !== "New"
    // ) {
    // this.props.toggleLoader({
    //   isLoading: true,
    // });
    // await getUserEmail();
    // await this.getContactEmail();
    // this.handleBasketChange("Email");
    // this.props.toggleLoader({
    //   isLoading: false,
    // });
    handleShowChooseLayout("Email", res);

    // } else {
    //   window.alert("Please select a basket first");
    // }
  };

  const handleCurrentRes = (res) => {
    setData({ ...data, currentRes: [res] });
  };

  const handleSetCover = (cover) => {
    // console.log("layout: ", layout);
    setData({ ...data, coverType: cover });
  };

  const handleSetLayout = (layout) => {
    // console.log("layout: ", layout);
    setLayoutType(layout);
    // setData({ ...data, layoutType: layout });
  };

  const handleShowChooseLayout = (value, res) => {
    console.log("Inside ChooseLayout");
    console.log({ res, value });
    // setData({
    //   ...data,
    //   showChooseLayout: true,
    //   goto: value,
    // });
    setShowChooseLayout(true);
    setGoto(value);
    handleCurrentRes(res);
    console.log({ data });
  };
  const hideChooseLayout = () => {
    // setData({ ...data, showChooseLayout: false });
    setShowChooseLayout(false);
  };

  const showPDFModal = () => {
    setData({ ...data, showPDFModal: true });
  };
  const hidePDFModal = () => {
    setData({ ...data, showPDFModal: false });
  };

  const showPreviewModal = () => {
    setData({ ...data, showPreviewModal: true });
  };
  const hidePreviewModal = () => {
    setData({ ...data, showPreviewModal: false });
  };
console.log(items);
  return (
    // <div className="es_results">
    //   <div
    //     id="ES_Results"
    //     className="List_result_container"
    //     // className="compact_result_container"
    //   >
    <>
      {items &&
        items.length !== 0 &&
        items.map((item, index) => (
         
          <Card 
            // style={{background: item.IsTiara === "1" || item.IsKWCushion === "1" || item.IsKwiatRadiant === "1" || item.IsKwiatEmeraldCut === "1" || item.IsKwiatOval === "1" || item.IsAshoka === "1" || item.IsFLRound === "1" ? "#E3EDF3" : "" }}
            style={{background: item.IsOH === "1" ? "#d6a69ad9" : (item.IsKwiatRound === "1" || item.IsKWCushion === "1" || item.IsKwiatRadiant === "1" || item.IsKwiatEmeraldCut === "1" || item.IsKwiatOval === "1" || item.IsAshoka === "1" || item.IsFLRound === "1" ? "#E3EDF3": "#f5f7f8")}}

            key={item.SerialNumber}
            onClick={(e) => {
              // if (e.target !== this) {
              //   return;
              // }
              // handleItemToView(item);
              // toggleSingleItem(true);
              // showItem(item._id);
              // if (!basket.show) {
              dispatch(toggleSingleView({ show: true, item: item }));
              // }
              // handleBackButton(true);
            }}
          >
            {["Grid1", "Grid2", "Grid3"].includes(viewType) && (
              <Card.Title>
                {item.transformType === "JewelrySerial" ||
                !item.transformType ? (
                  <>
                    {" "}
                    {item.SerialNumber && item.StyleNumber ? (
                      <>
                        <span>{item.SerialNumber}</span>
                        {viewType === "Grid1" ? "|" : ""}
                        <span> {item.StyleNumber}</span>
                      </>
                    ) : item.SerialNumber ? (
                      item.SerialNumber
                    ) : item.StyleNumber ? (
                      item.StyleNumber
                    ) : (
                      ``
                    )}
                    <div className="d-flex" style={{marginLeft: "auto"}}>
                      {item?.KTDpdfurl === "" && (
                        <span>
                          {item?.KTDpdfurl || (
                            <img
                              src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/ktd-icon.png"
                              height="16px"
                              width="33px"
                              alt="Tiara Icon"
                              onClick={() =>
                                toggleIframeModal({
                                  show: true,
                                  url: item.KTDpdfurl
                                })
                              }
                            />
                          )}
                        </span>
                      )}
                      {item?.IsMTS === "1" && ( 
                        <span>
                            <img
                              src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/mts-icon.png"
                              height="16px"
                              width="33px"
                              style={{cursor: 'inherit'}}
                              alt=""
                            />
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  <>{item.SerialNumber}</>
                )}
                {item.dwebb_vegas === "1" && <span>Kwiat WYNN ONLY</span>}
              </Card.Title>
            )}
            {viewType === "List" && (
              <div className="item_no">
                <span>{index + 1}</span>
              </div>
            )}
            <div className="image_container">
              <div className="item_image">
                <Card.Img
                  variant="top"
                  src={handleImage(item)}
                  onError={(event) => {
                    event.target.src =
                      "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
                  }}
                />
              </div>
              <span
                className={cx({
                  "product-status": true,
                  "text-capitalize": true,
                  RTV: item.IsRtv === "1",
                  Rough: item.IsRough === "1",
                  "in-production": item.SerialStatus === "In Production",
                  adjusted: item.SerialStatus === "Adjusted Out",
                  mounted: item.IsMounted === "1" || item.Warehouse,
                  sold:
                    item.IsSold === "1" ||
                    item.IsVirtual === "1" ||
                    item.IsPhantom === "1",
                  "is-showcase": item.InShowCase === "1",
                })}
                onClick={(e) =>
                  item.IsSold === "1" ? this.showSoldModal(e, item) : ""
                }
              >
                {item.IsSold === "1" ? "SOLD" : ""}
                {item.IsOH === "1" ? "-OH" : ""}
                {item.IsRtv === "1" ? "RTV " : ""}
                {/* {item.IsRough === "1" ? "Rough " : ""} */}
                {item.IsRough === "1" && item.CertReceived === "0" ? "Rough " : ""}
                {item.IsRough === "1" && item.CertReceived === "1" ? "Rough Graded " : ""}
                {item.SerialStatus === "In Production" ? "In Production" : ""}
                {item.SerialStatus === "Adjusted Out" ? "Adjusted Out" : ""}
                {item.Warehouse && item.Warehouse}
                {item.IsMounted === "1" && item.IsSold !== "1"
                  ? " mounted"
                  : ""}
                {item.InShowCase === "1" && item.InShowCase === "1"
                  ? "In Showcase"
                  : ""}
                {item.IsVirtual === "1" && " Virtual"}
                {item.IsPhantom === "1" && " Phantom"}
              </span>
            </div>
            <Card.Body>
              {viewType === "List" && (
                <Card.Title>
                  {item.transformType === "JewelrySerial" ||
                  !item.transformType ? (
                    <>
                      {" "}
                      {item.SerialNumber && item.StyleNumber ? (
                        <>
                          <span>{item.SerialNumber}</span>|
                          <span> {item.StyleNumber}</span>
                        </>
                      ) : item.SerialNumber ? (
                        item.SerialNumber
                      ) : item.StyleNumber ? (
                        item.StyleNumber
                      ) : (
                        ``
                      )}
                    </>
                  ) : (
                    <>
                      {item.SerialNumber}
                      {isValueEmpty(item.MountedStockNumber)
                        ? ` | ${item.MountedItemType} # ${
                            !isValueEmpty(item.MountedStockNumber)
                              ? item.MountedStockNumber
                              : ""
                          }`
                        : "Loose"}
                    </>
                  )}
                  <div className="d-flex" style={{marginLeft: "auto"}}>
                      {item?.KTDpdfurl !== "" && (
                        <span>
                          {item?.KTDpdfurl && (
                            <img
                              src="https://svgshare.com/i/G9L.svg"
                              height="14px"
                              width="30px"
                              alt="Tiara Icon"
                              onClick={() =>
                                toggleIframeModal({
                                  show: true,
                                  url: item.KTDpdfurl
                                })
                              }
                            />
                          )}
                        </span>
                      )}
                      {item?.IsMTS === "1" && ( 
                        <span>
                            <img
                              src="https://cdn7.kwiat.com/apps/kwiat-elastic-search/icons/mts-icon.png"
                              height="14px"
                              width="30px"
                              style={{cursor: 'inherit'}}
                              alt=""
                            />
                        </span>
                      )}
                    </div>
                </Card.Title>
              )}

              <div className="card-text">
                {viewType !== "Grid3" ? (
                  item.transformType === "JewelrySerial" ||
                  !item.transformType ? (
                    <>
                      <div className="item_description">{item.Description}</div>
                      {item.ItemSubtype === "Earrings - Stud Earrings" ? (
                        item.DiamondCarats ? (
                          <label>{item.DiamondCarats} cts dia</label>
                        ) : (
                          <></>
                        )
                      ) : item.RingSize ? (
                        <label>{item.RingSize}</label>
                      ) : (
                        <></>
                      )}
                      {item.ItemSubtype === "Earrings - Stud Earrings" ? (
                        item.Color && item.Clarity ? (
                          <label>
                            {item.Color}/{item.Clarity}
                          </label>
                        ) : item.Color ? (
                          <label>{item.Color}</label>
                        ) : item.Clarity ? (
                          <label>{item.Clarity}</label>
                        ) : (
                          <></>
                        )
                      ) : item.DiamondCarats ? (
                        <label>{item.DiamondCarats} cts dia</label>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : item.transformType === "DiamondSerial" ? (
                    <div className="item_description">
                      <span>
                        {item.Shape && item.Shape !== null
                          ? `${item.Shape} ${item.GemstoneType || ""}`
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {isValueEmpty(item.DiamondCaratWeight) &&
                        isMultipleValueEmpty(item, "DiamondColorRange")
                          ? `${Number(item.DiamondCaratWeight).toFixed(
                              2
                            )}cts | ${isMultipleValueEmpty(
                              item,
                              "DiamondColorRange"
                            )} `
                          : isValueEmpty(item.DiamondCaratWeight)
                          ? `${Number(item.DiamondCaratWeight).toFixed(2)}cts`
                          : isMultipleValueEmpty(item, "DiamondColorRange")
                          ? isMultipleValueEmpty(item, "DiamondColorRange")
                          : ""}
                      </span>
                    </div>
                  ) : item.transformType === "GemstoneSerial" ? (
                    <div className="item_description">
                      <span>
                        {isValueEmpty(item.Shape)
                          ? `${item.Shape} ${item.GemstoneType}`
                          : ""}
                      </span>
                      <span>
                        {" "}
                        {isValueEmpty(item.CountryofOrigin) &&
                        isValueEmpty(item.GemEnhancement)
                          ? ` ${isValueEmpty(
                              item.CountryofOrigin
                            )} | ${isValueEmpty(item.GemEnhancement)} `
                          : isValueEmpty(item.CountryofOrigin)
                          ? `${isValueEmpty(item.CountryofOrigin)}`
                          : isValueEmpty(item.GemEnhancement)
                          ? isValueEmpty(item.GemEnhancement)
                          : ""}
                      </span>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div className="item_price">
                  {item.RetailPrice ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!basket.show) {
                          handleStockPopUpModal(true, item);
                        }
                      }}
                    >
                      {currencyFormatter.format(`${item.RetailPrice}`, {
                        code: "USD",
                        precision: 0,
                      }) || ""}
                      <sup>(R)</sup>
                    </span>
                  ) : (
                    ""
                  )}{" "}
                  {item.WholesalePrice && showWholesale ? (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!basket.show) {
                          handleStockPopUpModal(true, item);
                        }
                      }}
                    >
                      {isMultipleValueEmpty(item, "WholesalePrice")}
                      <sup>(W)</sup>
                    </span>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>
            </Card.Body>
            {/* {viewType === "List" && ( */}
            <div className="result_action_group">
              {!basket.show ? (
                <button
                  onClick={(e) => {
                    // if (e.target === this) {
                    e.stopPropagation();
                    dispatch(addToCart({ product: item }));
                    // addItemToBasket(item);
                    // }
                  }}
                >
                  <img src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/add-to-basket.png"></img>
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    if (window.confirm("Confirm Delete?")) {
                      dispatch(removeFromCart(item));
                    }
                  }}
                >
                  <span>-</span>
                </button>
              )}
            </div>
            {/* )} */}
          </Card>
        ))}
      {item && Object.keys(item).length !== 0 && (
        <SingleItem
          isValueEmpty={isValueEmpty}
          isMultipleValueEmpty={isMultipleValueEmpty}
          handleSetCover={handleSetCover}
          handleIncludeGIA={handleIncludeGIA}
          showChooseLayout={handleShowChooseLayout}
          handleEmail={handleEmail}
          handleStockPopUpModal={handleStockPopUpModal}
        />
      )}
      <PreviewEmailModal
        show={data.showPreviewModal}
        hide={hidePreviewModal}
        layoutType={layoutType}
        coverType={data.coverType}
        showChooseLayout={handleShowChooseLayout}
        item={
          data.currentRes.length && data.currentRes[0] ? data.currentRes : []
        }
        handleCurrentRes={handleCurrentRes}
        filename={"Kwiat-Fred-Leighton"}
        includeGIA={data.includeGIA}
        // user={this.stat}
      />
      <PDFModal
        show={data.showPDFModal}
        hide={hidePDFModal}
        layoutType={layoutType}
        coverType={data.coverType}
        showChooseLayout={handleShowChooseLayout}
        item={
          data.currentRes.length && data.currentRes[0] ? data.currentRes : []
        }
        handleCurrentRes={handleCurrentRes}
        filename={"KWFL"}
        includeGIA={data.includeGIA}
      />
      <ChooseLayoutModal
        show={showChooseLayout}
        hide={hideChooseLayout}
        setLayout={handleSetLayout}
        setCover={handleSetCover}
        showPreviewModal={showPreviewModal}
        showPDFModal={showPDFModal}
        goto={goto}
        showChooseLayout={handleShowChooseLayout}
        coverType={data.coverType}
        includeGIA={data.includeGIA}
        handleIncludeGIA={handleIncludeGIA}
        item={
          data.currentRes.length && data.currentRes[0] ? data.currentRes : []
        }
      />
    </>
    //   </div>
    // </div>
  );
}
