import React, { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Appbase from "appbase-js";
import {
  AppbaseAppUrl,
  AppbaseCredentials,
  AppbaseCoverApp,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { toggleLoader } from "../../actions";

export default function CoverEdit(props) {
  let { show, handleCoverEdit, coverDetails } = props;
  const [state, setState] = useState({
    id: "",
    coverName: "",
    coverCategory: "",
    coverDisplayOrder: "",
    coverStatus: "",
    // coverDateCreated: "",
    coverDescription: "",
    coverTitle: "",
    coverLogo: "",
    coverImage: "",
  });
  const Dispatch = useDispatch();

  // const updatedStateValue = useMemo(
  //   () => {
  //     return setTemplateDetails();
  //   },
  //   [templateDetails]
  // );

  const handleCoverReset = () => {
    setState({
      id: "",
      coverName: "",
      coverCategory: "",
      coverDisplayOrder: "",
      coverStatus: "",
      // coverDateCreated: "",
      coverDescription: "",
      coverTitle: "",
      coverLogo: "",
      coverImage: "",
    });
  };

  const setCoverDetails = () => {
    setState({
      ...state,
      id: coverDetails.id ? coverDetails.id : "",
      coverName: coverDetails.Name ? coverDetails.Name : "",
      coverCategory: coverDetails.Category ? coverDetails.Category : "",
      coverDisplayOrder: coverDetails.DisplayOrder
        ? coverDetails.DisplayOrder
        : "",
      // coverDateCreated: coverDetails.DateCreated
      //   ? coverDetails.DateCreated
      //   : "",
      coverStatus: coverDetails.Status ? coverDetails.Status : "",
      coverDescription: coverDetails.Description
        ? coverDetails.Description
        : "",
      coverTitle: coverDetails.Title ? coverDetails.Title : "",
      coverLogo: coverDetails.Logo ? coverDetails.Logo : "",
      coverImage: coverDetails.Image ? coverDetails.Image : "",
    });
  };

  const addCover = async () => {
    Dispatch(
      toggleLoader({
        isLoading: true,
      })
    );
    const appbaseRef = Appbase({
      app: AppbaseCoverApp,
      url: AppbaseAppUrl,
      credentials: AppbaseCredentials,
    });
    let currentdate = new Date();
    let dateTime =
      currentdate.getFullYear() +
      "" +
      (currentdate.getMonth() + 1) +
      "" +
      currentdate.getDate() +
      "" +
      currentdate.getHours() +
      "" +
      currentdate.getMinutes() +
      "" +
      currentdate.getSeconds();
    let payload = {
      DateCreated: dateTime,
      Category: state.coverCategory,
      Name: state.coverName,
      DisplayOrder: state.coverDisplayOrder,
      Description: state.coverDescription,
      Status: state.coverStatus,
      Title: state.coverTitle,
      Logo: state.coverLogo,
      Image: state.coverImage,
    };

    let response = await appbaseRef
      .index({
        ...(![undefined, null, ""].includes(state.id) && { id: state.id }),
        body: payload,
      })
      .then(function (res) {
        // console.log("Template added successfully: ", res);
        return res;
      })
      .catch(function (err) {
        console.log("Cover error: ", err);
        toast.error(`Error in adding cover`, {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        });
        return err;
      });
    if (
      response &&
      response.result &&
      ["created", "updated"].includes(response.result)
    ) {
      props.handleCoverRefresh({
        id: response._id,
        DateCreated: payload.DateCreated,
        Category: payload.Category,
        Name: payload.Name,
        DisplayOrder: payload.DisplayOrder,
        Description: payload.Description,
        Status: payload.Status,
        Title: payload.Title,
        Logo: payload.Logo,
        Image: payload.Image,
      });
      Dispatch(
        toggleLoader({
          isLoading: false,
        })
      );
      toast.success(
        `${
          state.id ? `Cover updated successfully` : `Cover Added successfully`
        }`,
        {
          // position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          pauseOnHover: false,
          theme: "colored",
        }
      );
      handleCoverEdit(false, "");
    } else {
      toast.error(`Error in adding cover`, {
        // position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    setCoverDetails();
  }, [coverDetails]);

  return (
    <Modal
      show={show}
      className="template-edit-modal"
      onHide={() => {
        handleCoverEdit(false, "");
        handleCoverReset();
      }}
    >
      <Modal.Header closeButton>Cover Details</Modal.Header>
      <Modal.Body>
        <div className="template_edit">
          <div className="template_input">
            <label> Name:</label>
            <input
              type="text"
              placeholder="Cover Name..."
              value={state.coverName}
              onChange={(e) => {
                setState({ ...state, coverName: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label> Category:</label>
            <input
              type="text"
              placeholder="Cover Category..."
              value={state.coverCategory}
              onChange={(e) => {
                setState({ ...state, coverCategory: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Display Order:</label>
            <input
              type="text"
              placeholder="Display Order..."
              value={state.coverDisplayOrder}
              onChange={(e) => {
                setState({ ...state, coverDisplayOrder: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Status:</label>
            <select
              value={state.coverStatus}
              onChange={(e) =>
                setState({ ...state, coverStatus: e.target.value })
              }
            >
              <option value="Active">Active</option>
              <option value="Pending">Pending</option>
            </select>
            {/* <input
              type="text"
              placeholder="Status..."
              value={state.coverStatus}
              onChange={(e) => {
                setState({ ...state, coverStatus: e.target.value });
              }}
            /> */}
          </div>
          <div className="template_input">
            <label>Description:</label>
            <input
              type="text"
              placeholder="Description ..."
              value={state.coverDescription}
              onChange={(e) => {
                setState({ ...state, coverDescription: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Title:</label>
            <input
              type="text"
              placeholder="Title ..."
              value={state.coverTitle}
              onChange={(e) => {
                setState({ ...state, coverTitle: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Logo:</label>
            <input
              type="text"
              placeholder="Logo ..."
              value={state.coverLogo}
              onChange={(e) => {
                setState({ ...state, coverLogo: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <label>Image:</label>
            <input
              type="text"
              placeholder="Image ..."
              value={state.coverImage}
              onChange={(e) => {
                setState({ ...state, coverImage: e.target.value });
              }}
            />
          </div>
          <div className="template_input">
            <button onClick={() => addCover()}>Save</button>
            <button onClick={() => handleCoverReset()}>Clear</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
